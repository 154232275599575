//users are sent to this page after receiving one of two types of emails: 
//1. a welcome email as a first time user
//2. an email from submitting a login code request from ForgotPassword

import React, { FunctionComponent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { ReduxState } from '../redux/store';
import { Dispatch, Action } from 'redux';
import { resetUserPassword } from '../redux/actions/userActions';

import Form from '../components/Form';
import Input from '../components/Input';
import ButtonSubmit from '../components/ButtonSubmit';
import Footer from '../components/Footer';

const mapStateToProps = ({ user }: ReduxState) => {
    const { orgId } = user
    return { orgId }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        resetUserPassword: (token: string, password: string) => dispatch(resetUserPassword(token, password))
    }
}

interface Props {
    orgId: number,
    resetUserPassword: Function
}


const ResetPassword: FunctionComponent<Props> = ({ orgId, resetUserPassword }) => {

    let history: any = useHistory()
    let params: any = useParams()
    const userToken: number = params.token

    //state
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    //events
    const handleFormSubmit = () => {
        if (newPassword !== confirmPassword) {
            alert('Passwords must match')
        } else {
            resetUserPassword(userToken, confirmPassword)
            //route to Login 
            history.push('/')
        }
    }

    return (
        <div className="p-4 grid grid-cols-1 row-auto gap-y-4">

            {/*Logo*/}
            <div className="bg-white rounded-md shadow m-2 flex justify-center items-center mx-auto">
                <img alt="Oz Reputation" src="/oz-rep-logo.png" className="p-4" />
            </div>

            {/*Form*/}
            <div className="bg-white rounded-lg shadow p-6 gap-32">
                <div>
                    <p className="text-ozRepGreen text-center text-sm mb-4">Please reset your password below:</p>
                </div>

                <Form submitForm={handleFormSubmit}>

                    <Input
                        labelText="New Password"
                        inputType="password"
                        value={newPassword}
                        onChange={setNewPassword} />

                    <Input
                        labelText="Confirm Password"
                        inputType="password"
                        value={confirmPassword}
                        onChange={setConfirmPassword} />

                    <ButtonSubmit
                        buttonText={'Reset'}
                    />

                </Form>
            </div>

            <Footer />

        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
