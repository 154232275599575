import { NotificationState, Constants } from '../types/notificationTypes';

const initializeState = () : NotificationState => {
    return { notificationType: '', notificationMessage: '' }
}

export default function notificationReducer(state = initializeState(), action: any) {
    switch (action.type) {
        case Constants.SHOW_SUCCESS:
            return { ...state, notificationType: 'success', notificationMessage: action.payload }

        case Constants.SHOW_ERROR:
            return { ...state, notificationType: 'error', notificationMessage: action.payload }

        case Constants.SHOW_WARNING:
            return { ...state, notificationType: 'warning', notificationMessage: action.payload }

        case Constants.CLEAR_NOTIFICATION:
            return { ...state, notificationType: '', notificationMessage: '' }

        default:
            return state
    }
}