import React, { FunctionComponent } from 'react';

interface Props {
    buttonText: string,
    onClick: Function
}

const Button: FunctionComponent<Props> = ({ buttonText, onClick }) => {

    const handleClick = () => {
        onClick()
    }
    return (
        <div className="grid grid-cols-2" >
            <button className="bg-ozRepGreen col-start-2 flex justify-center items-center rounded-full text-white p-4 text-xl font-semibold shadow cursor-pointer focus:outline-none" onClick={handleClick}>
                {buttonText}
            </button>
        </div>
    )
}

export default Button;