import React, { FunctionComponent } from 'react';

import Footer from './Footer';

interface Props {
    title: string
}

const Card: FunctionComponent<Props> = ({ title, children }) => {
    return (
        <div>
            <div className="flex-grow flex-shrink flex-auto">

                {/*---Card---*/}
                <div className="m-3">
                    <div className='bg-ozRepGreen text-white text-center text-xl rounded-t-lg p-4 font-semibold'>
                        {title}
                    </div>
                    <div className='bg-white rounded-b-lg shadow p-6'>
                        {children}
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}

export default Card;

