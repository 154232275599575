import React, { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { ReduxState } from "../../redux/store";
import { Dispatch, Action } from "redux";
import {
  getOrgAndRoofInfo,
  trackClicked,
} from "../../redux/actions/customerActions";
import { Platform } from "../../redux/types/customerTypes";

import Nav from "../../components/Nav";
import Card from "../../components/Card";
import OrgLogo from "../../components/OrgLogo";
import Pill from "../../components/Pill";

const mapStateToProps = ({ customer }: ReduxState) => {
  const { orgName, orgLogo, roofName, reviewURLs } = customer;
  return { orgName, orgLogo, roofName, reviewURLs };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    getOrgAndRoofInfo: (requestId: number) =>
      dispatch(getOrgAndRoofInfo(requestId)),
  };
};

interface Props {
  orgName: string;
  orgLogo: string | null;
  roofName: string;
  reviewURLs: Platform[];
  getOrgAndRoofInfo: Function;
}

const Platforms: FunctionComponent<Props> = ({
  orgName,
  orgLogo,
  roofName,
  reviewURLs,
  getOrgAndRoofInfo,
}) => {
  const params: any = useParams();
  const token: string = params.token;

  useEffect(() => {
    if (!orgName && !orgLogo && !roofName) {
      getOrgAndRoofInfo(token);
    }
  });

  const handlePlatformClick = (platform: Platform) => {
    trackClicked(token, platform);
    window.open(platform.url, "_blank");
  };

  return (
    <div>
      <Nav
        backButtonExists={false}
        middleExists={false}
        buttonText=""
        buttonLink=""
        handleButtonClick={() => false}
        adminHomeExists={false}
        logoIsLink={false}
      />

      <Card title={"Select a Platform"}>
        <div className="grid grid-cols-1 row-auto gap-y-6">
          <OrgLogo orgName={orgName} rooftopName={roofName} logoSrc={orgLogo} />

          <div className="grid grid-cols-1 row-auto gap-y-6">
            {reviewURLs.map((platform: Platform) => (
              <div onClick={() => handlePlatformClick(platform)}>
                <Pill
                  color={"bg-ozRepGreen"}
                  textColor={"text-white"}
                  shadow={true}
                >
                  <p>{platform.name}</p>
                </Pill>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Platforms);
