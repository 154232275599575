import React, { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { Flag, Mail, Check, Trash2 } from "react-feather";
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import {
  flagReview,
  markReviewResolved,
  deleteReview,
} from "../../../redux/actions/adminActions/adminReviewActions";

import Pill from "../../../components/Pill";
import ButtonSubmit from "../../../components/ButtonSubmit";
import TextArea from "../../../components/TextArea";
import Form from '../../../components/Form';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    flagReview: (orgId: number, reviewId: number, data: object) =>
      dispatch(flagReview(orgId, reviewId, data)),
    markReviewResolved: (orgId: number, reviewId: number, data: object) =>
      dispatch(markReviewResolved(orgId, reviewId, data)),
    deleteReview: (orgId: number, reviewId: number) =>
      dispatch(deleteReview(orgId, reviewId)),
  };
};

interface Props {
  reviewId: number;
  orgId: number;
  flaggedState: boolean;
  resolvedState: boolean;
  flagReview: Function;
  markReviewResolved: Function;
  deleteReview: Function;
  //replyReview:Function;
  phone: string
}

const ManageReviewControls: FunctionComponent<Props> = ({
  reviewId,
  orgId,
  flaggedState,
  resolvedState,
  flagReview,
  markReviewResolved,
  deleteReview,
  phone
}) => {
  const history:any = useHistory()
  const handleFlag = () => {
    //console.log(`original flaggedState: ${flaggedState}`);
    const data = { flagged: !flaggedState };
    flagReview(orgId, reviewId, data);
  };

  const handleResolve = () => {
    //console.log(`original resolvedState: ${resolvedState}`);
    const data = { resolved: !resolvedState };
    markReviewResolved(orgId, reviewId, data);
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this review?')) {
    deleteReview(orgId, reviewId);
    history.push('/admin-home')
    }
  };

  
  return (
    <div className="grid grid-cols-1 row-auto gap-y-6 text-white mt-8">
      <div onClick={handleFlag}>
        <Pill
          color={"bg-ozRepGreen"}
          textColor={"text-white"}
          shadow={true}
          emphasized={flaggedState ? false : true}
        >
          <p>{flaggedState ? "Remove Flag" : "Flag For Follow Up"}</p>
          <Flag />
        </Pill>
      </div>

      {/**TODO */}
      {/* <Link to='/email-review/:reviewId'>
                <Pill
                    color={'bg-ozRepGreen'}
                    textColor={'text-white'}
                    shadow={true}>
                    <p>Email Review</p>
                    <Mail />
                </Pill>
            </Link> */}

      <div onClick={handleResolve}>
        <Pill
          color={"bg-ozRepGreen"}
          textColor={"text-white"}
          shadow={true}
          emphasized={resolvedState ? false : true}
        >
          <p>{resolvedState ? "Mark Unresolved" : "Mark Resolved"}</p>
          <Check />
        </Pill>
      </div>

      <div onClick={handleDelete}>
        <Pill color={"bg-red-700"} textColor={"text-white"} shadow={true}>
          <p>Delete</p>
          <Trash2 />
        </Pill>
      </div>
      <div>
      <a href={`sms:${phone}&body=`}>
        <Pill
          color={"bg-ozRepGreen"}
          textColor={"text-white"}
          shadow={true}
          emphasized={true}
        >
          <p>Reply</p>
        </Pill></a>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ManageReviewControls);
