import { OzRepAPI } from "../../OzRepAPIClient";
import { Constants } from "../../types/reviewTypes";
import { Dispatch } from "redux";

export function getReviews(orgId: number): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.get(`/v2/organizations/${orgId}/reviews`)
      .then((res) => {
        const reviews = res.data.reviews.map((review: any) => {
          let title: string = "";
          if (review.meesage &&review.message.length >= 35) {
            title += `${review.message.slice(0, 35)}...`;
          } else if (review.message){
            title += review.message;
          } else{
              title =''
          }
          //console.log(reviews)
          return {
            id: review.id,
            link: `manage-review/${review.id}`,
            title: title,
            subTitle: review.phone,
            emphasized: review.resolved, //!review.resolved
            resolved: review.resolved, //review.resolved
            flagged: review.flagged, //review.flagged
            date: review.inserted_at,
            roofId: review.rooftop_id,
            name: review.name,
            phoneNum: review.phone,
            message: review.message,
            wantsResponse: review.wants_response,
            responseSent: review.response_sent,
            //sentTo: []
          };
        });

        dispatch({ type: Constants.SET_REVIEWS, payload: reviews });
      })
      .catch((err) => console.log(err));
  };
}

export function getReview(): void {}

export function flagReview(orgId: number, reviewId: number, data: object): any {
  return function (dispatch: Dispatch) {
    // console.log(`orgId: ${orgId}, reviewId: ${reviewId}`);
    // console.log("flagged state sent to change db:", data);
    OzRepAPI.patch(
      `v2/organizations/${orgId}/reviews/${reviewId}`,
      data
    )
      .then((res) => {
        dispatch(getReviews(orgId));
      })
      .catch((err) => console.log(err));
  };
}

export function markReviewResolved(
  orgId: number,
  reviewId: number,
  data: object
): any {
  return function (dispatch: Dispatch) {
    // console.log(`orgId: ${orgId}, reviewId: ${reviewId}`);
    // console.log("resolved state sent to change db:", data);

    OzRepAPI.patch(
      `v2/organizations/${orgId}/reviews/${reviewId}`,
      data
    )
      .then((res) => {
        dispatch(getReviews(orgId));
      })
      .catch((err) => console.log(err));
  };
}
// export function replyReview(
//     orgId: number,
//     reviewId: number,
//     reply: string
//   ): any {
//     return function (dispatch: Dispatch) {
//       OzRepAPI.post(
//         `v2/organizations/${orgId}/reviews/${reviewId}/reply`,
//         {data:reply}
//       )
//         .then((res) => {
//           dispatch(getReviews(orgId));
//         })
//         .catch((err) => console.log(err));
//     };
//   }
export function emailReview(): void {}

export function deleteReview(orgId: number, reviewId: number): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.delete(`/v2/organizations/${orgId}/reviews/${reviewId}`)
      .then((res) => {
        dispatch(getReviews(orgId));
      })
      .catch((err) => console.log(err));
  };
}
