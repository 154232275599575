export interface ReviewsState {
    reviews: ReviewObject[]
}

export interface ReviewObject {
    id: number
    link: string
    title: string
    subTitle: string
    emphasized: boolean      //marks if the review has been resolved or not
    flagged: boolean
    date: Date
    roofId: number
    name: string
    phoneNum: string
    message: string
    wantsResponse: boolean
    resolved: boolean
    sentTo: number[]
}

export enum Constants {
    SET_REVIEWS = "SET_REVIEWS",
    SET_REVIEW = "SET_REVIEW"
}
