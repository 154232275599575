import { userState, Constants } from '../types/userTypes';

const initializeState = (): userState => {
  let userString = localStorage.getItem('user')
  if (userString) {
    let user = JSON.parse(userString)

    return {
      userId: user.id,
      userName: user.name,
      userEmail: user.email,
      userRole: user.role,
      orgId: user.organization_id,
      defaultStore: user.default_store
    }
  }
  return {
    userId: null,
    userName: '',
    userEmail: '',
    userRole: 'user',
    orgId: null,
    defaultStore: null
  }
}

export default function userReducer(state = initializeState(), action: any) {
  switch (action.type) {
    case Constants.SET_USER:
      const { id, name, email, role, organization_id, default_store } = action.payload
      localStorage.setItem('user', JSON.stringify(action.payload))
      return { ...state, userId: id, userName: name, userEmail: email, userRole: role, orgId: organization_id, defaultStore: default_store }

    default:
      return state
  }
}