import axios from 'axios';

const OzRepAPI = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
})

OzRepAPI.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token")
    config.headers.Authorization = `Bearer ${token}`

    return config
});

export { OzRepAPI }