import { authState, Constants } from '../types/authTypes';

const initialState: authState = {
    authenticated: false,
    token: '',
}

export default function authReducer(state = initialState, action: any) {
    switch (action.type) {
        case Constants.LOGIN_USER:
            return { ...state, authenticated: true, token: action.payload }

        case Constants.LOGOUT_USER:
            return initialState

        default:
            return state
    }
}