import React, { FunctionComponent } from 'react';
import { Flag } from 'react-feather';

interface TwoRowPillProps {
    title: string
    subTitle: string
    emphasized: boolean
    flagged: boolean
}

const TwoRowPill: FunctionComponent<TwoRowPillProps> = ({ title, subTitle, emphasized, flagged }) => {
    return (
        <div className={'py-2 px-6 my-4 w-full rounded-full shadow flex items-center ' + (emphasized ? 'bg-ozRepGreen text-white' : 'bg-white text-textGray border border-solid border-gray-400')}>
            <div className="text-contents min-w-0">
                <div className="title-container text-lg font-bold truncate">
                    {title}
                </div>
                <div className="subtitle-container text-xs font-light">
                    {subTitle}
                </div>
            </div>
            <div className={'flag-container min-w-0 ' + (emphasized ? 'text-white' : 'text-textGray')}>
                {flagged ? <Flag /> : ''}
            </div>
        </div>
    );
}

export default TwoRowPill;