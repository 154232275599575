import { Constants, ReviewsState } from '../types/reviewTypes';

const initialState: ReviewsState = {
    reviews: []
}

export default function reviewReducer(state = initialState, action: any) {
    switch (action.type) {
        case Constants.SET_REVIEWS:
            return { reviews: action.payload }
        default:
            return state
    }
}
