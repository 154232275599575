import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Trash2 } from "react-feather";

import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import {
  updateRooftop,
  deactivateRooftop,
} from "../../../redux/actions/adminActions/adminRoofActions";

//NOT mapped to props via connect function
import { getOrgRoof } from "../../../redux/actions/orgActions";

import Nav from "../../../components/Nav";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import ButtonSubmit from "../../../components/ButtonSubmit";
import Pill from "../../../components/Pill";

const mapDipsatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    updateRooftop: (orgId: number, roofId: number, data: object) =>
      dispatch(updateRooftop(orgId, roofId, data)),
    deactivateRooftop: (orgId: number, roofId: number) =>
      dispatch(deactivateRooftop(orgId, roofId)),
  };
};

interface Props {
  updateRooftop: Function;
  deactivateRooftop: Function;
}

const ManageRooftop: FunctionComponent<Props> = ({
  updateRooftop,
  deactivateRooftop,
}) => {
  const params: any = useParams();
  const history: any = useHistory();

  //state
  const [name, setName] = useState("");
  const [platforms, setPlatforms] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    platforms: "",
  });

  useEffect(() => {
    const { orgId, roofId } = params;
    getOrgRoof(orgId, roofId)
      .then((res: any) => {
        const { name, review_urls, message_body, email_list } =
          res.data.rooftop;
        setName(name);

        if (review_urls === null) {
          setPlatforms([]);
        } else {
          setPlatforms(review_urls);
        }
        setMessage(message_body);
        setEmail(email_list);
      })
      .catch((err: any) => {
        //what error handling method should we use?
      });
  }, []);

  //events
  const handleFormSubmit = () => {
    let nameError = "";
    let platformsError = "";
    const enabled = platforms.filter((platform) => platform.enabled);

    if (!name) nameError = "Must not be empty";
    if (enabled.length === 0)
      platformsError = "Must have minimum 1 platform enabled";

    if (!nameError && !platformsError) {
      const { orgId, roofId } = params;
      const data = {
        name,
        review_urls: platforms,
        message_body: message,
        email: email,
      };
      updateRooftop(orgId, roofId, data);
      history.push("/rooftops");
    } else {
      let errorsObject = {
        name: nameError,
        platforms: platformsError,
      };

      setErrors(errorsObject);
    }
  };

  const handleRoofDelete = () => {
    if (window.confirm("Are you sure you want to delete this location?")) {
      const { orgId, roofId } = params;
      deactivateRooftop(orgId, roofId);
      history.push("/rooftops");
    }
  };

  return (
    <div>
      <Nav
        backButtonExists={true}
        middleExists={false}
        buttonText={""}
        buttonLink={""}
        handleButtonClick={() => false}
        adminHomeExists={true}
        logoIsLink={true}
      />
      <Card title={"Manage Location"}>
        <Form submitForm={handleFormSubmit}>
          <Input
            labelText="Location Name"
            inputType="text"
            value={name}
            onChange={setName}
          />
          {errors.name && <p className="-mt-5 text-red-500">{errors.name}</p>}

          <TextArea
            labelText="Review Request Message Text"
            name="sms-message"
            value={message}
            maxLength={80}
            onChange={setMessage}
          />

          <div className="mb-4">
            <span className="flex justify-between">
              <label>Select Review Platforms</label>
              <a
                href="https://docs.ozreputation.com/"
                target="_BLANK"
                rel="noopener noreferrer"
              >
                <p className="text-ozRepGreen text-right italic">
                  What's this?
                </p>
              </a>
            </span>

            <div className="border-solid border-2 border-ozRepGreen p-8 grid grid-cols-1 gap-y-6">
              {platforms.map((platform, index) => (
                <div key={index}>
                  <Input
                    labelText={platform.name}
                    name="url"
                    value={platform.url}
                    inputType="text"
                    required={platform.enabled ? true : false}
                    onChange={(url: string) => {
                      platform.url = url;
                      setPlatforms([...platforms]);
                    }}
                  />
                  <div className="flex items-center">
                    <span className="mr-2">Enabled:</span>
                    <input
                      checked={platform.enabled}
                      name="enabled"
                      type="checkbox"
                      onChange={() => {
                        platform.enabled = !platform.enabled;
                        setPlatforms([...platforms]);
                      }}
                    />
                  </div>
                  {errors.platforms && (
                    <p className="text-red-500">{errors.platforms}</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div>
            <Input
              labelText="Email Notification List (separate with ','"
              inputType="text"
              value={email ? email : ""}
              onChange={setEmail}
            />
          </div>
          <ButtonSubmit buttonText="Update" />
        </Form>

        <div onClick={handleRoofDelete} className="cursor-pointer mt-6">
          <Pill color={"bg-red-700"} textColor={"text-white"} shadow={true}>
            <p>Delete Location</p>
            <Trash2 />
          </Pill>
        </div>
      </Card>
    </div>
  );
};

export default connect(null, mapDipsatchToProps)(ManageRooftop);
