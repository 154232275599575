import React, { FunctionComponent } from 'react';
import { Heart } from 'react-feather';

import Pill from './Pill';

interface Props {
    pillText: string
}

const PillCurrentDefault: FunctionComponent<Props> = ({ pillText }) => {

    return (
        <fieldset className="border-solid border-2 border-ozRepGreen px-1 pb-1 rounded-full">
            <legend className="text-ozRepGreen bg-white text-xs px-2 rounded-full">Current Default</legend>
            <Pill
                color={'bg-ozRepGreen'}
                textColor={'text-white'}
                shadow={false}>
                <p>{pillText}</p>
                <Heart fill="white" />
            </Pill>
        </fieldset>
    )
}

export default PillCurrentDefault;

