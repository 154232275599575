import { OzRepAPI } from '../OzRepAPIClient';
import { Constants } from '../types/developerTypes';
import { Constants as notificationConsts } from '../types/notificationTypes';
import { showNotification } from '../actions/notificationActions';
import { Dispatch } from 'redux';

export function getDevTokens(orgId: number): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.get(`/v2/organizations/${orgId}/apitokens`)
            .then(res => {
                dispatch({ type: Constants.SET_DEV_TOKENS, payload: res.data.api_tokens })
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export function createDevToken(orgId: number, api_token: object): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.post(`/v2/organizations/${orgId}/apitokens`, { api_token })
            .then(res => {
                dispatch({ type: Constants.SET_NEW_TOKEN_VALUE, payload: res.data.api_token })
                dispatch(getDevTokens(orgId))
                const message = "Token created"
                dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message))
            })
            .catch(err => {
                const message = "Failed to created token"
                dispatch(showNotification(notificationConsts.SHOW_ERROR, message))
            })
    }
}

export function clearNewTokenValue(): any {
    return {
        type: Constants.CLEAR_NEW_TOKEN_VALUE
    }
}

export function revokeDevToken(orgId: number, tokenId: number): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.patch(`/v2/organizations/${orgId}/apitokens/${tokenId}/deactivate`)
            .then(res => {
                dispatch(getDevTokens(orgId))
                const message = "Token revoked"
                dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message))
            })
            .catch(err => {
                const message = "Failed to revoke token"
                dispatch(showNotification(notificationConsts.SHOW_ERROR, message))
            })
    }
}