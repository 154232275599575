export interface developerState {
    devTokens: tokenObject[],
    newTokenValue: string
}

export interface tokenObject {
    id: number | null
    name: string
    inserted_at: Date
    updated_at: Date | string
    active: boolean
}

export enum Constants {
    SET_DEV_TOKENS = "SET_DEV_TOKENS",
    SET_NEW_TOKEN_VALUE = "SET_NEW_TOKEN_VALUE",
    CLEAR_NEW_TOKEN_VALUE = "CLEAR_NEW_TOKEN_VALUE"
}