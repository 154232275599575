import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { updateForgottenPassword } from '../redux/actions/userActions';

import Form from '../components/Form';
import Input from '../components/Input';
import ButtonSubmit from '../components/ButtonSubmit';
import Footer from '../components/Footer';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        updateForgottenPassword: (email: string) => dispatch(updateForgottenPassword(email))
    }
}

interface Props {
    updateForgottenPassword: Function
}

const ForgotPassword: FunctionComponent<Props> = ({ updateForgottenPassword }) => {

    //state
    const [email, setEmail] = useState('')

    //events
    const handleFormSubmit = () => {
        updateForgottenPassword(email)
    }

    return (
        <div className="p-4 grid grid-cols-1 row-auto gap-y-4">

            {/*Logo*/}
            <div className="bg-white rounded-md shadow m-2 flex justify-center items-center mx-auto">
                <img alt="Oz Reputation" src="/oz-rep-logo.png" className="p-4" />
            </div>

            {/*Form*/}
            <div className="bg-white rounded-lg shadow p-6 gap-32">
                <div>
                    <p className="text-ozRepGreen text-center text-sm mb-4">Enter your email to recieve a login code.</p>
                </div>

                <Form submitForm={handleFormSubmit}>

                    <Input
                        labelText={'Email'}
                        inputType={'text'}
                        inputMode='email'
                        value={email}
                        onChange={setEmail} />

                    <ButtonSubmit
                        buttonText={'Send'}
                    />

                    <Link to='/'><p className="text-ozRepGreen text-right underline text-lg">Login</p></Link>

                </Form>
            </div>

            <Footer />

        </div>
    )
}

export default connect(null, mapDispatchToProps)(ForgotPassword);


