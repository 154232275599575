import { OzRepAPI } from '../../OzRepAPIClient';
import { getOrgRoofs } from '../orgActions';
import { Constants as notificationConsts } from '../../types/notificationTypes';
import { showNotification } from '../notificationActions';
import { Dispatch } from 'redux';

//create a new rooftop
export function createRooftop(orgId: number, rooftop: object): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.post(`/v2/organizations/${orgId}/rooftops`, { rooftop })
            .then(res => {
                dispatch(getOrgRoofs(orgId))
                const message = "Location created"
                dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message))
            })
            .catch(err => {
                const message = "Failed to create location"
                dispatch(showNotification(notificationConsts.SHOW_ERROR, message))
            })
    }
}

//update fields on a rooftop
export function updateRooftop(orgId: number, roofId: number, rooftop: object): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.patch(`/v2/organizations/${orgId}/rooftops/${roofId}`, { rooftop })
            .then(res => {
                const message = "Location updated"
                dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message))
            })
            .catch(err => {
                const message = "Error updating location"
                dispatch(showNotification(notificationConsts.SHOW_ERROR, message))
            })
    }
}

//deactivate a rooftop on an organization
export function deactivateRooftop(orgId: number, roofId: number): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.delete(`/v2/organizations/${orgId}/rooftops/${roofId}`)
            .then(res => {
                dispatch(getOrgRoofs(orgId))
                const message = "Location deleted"
                dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message))
            })
            .catch(err => {
                const message = "Failed to delete location"
                dispatch(showNotification(notificationConsts.SHOW_ERROR, message))
            })
    }
}