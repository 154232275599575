import React, { FunctionComponent } from 'react';

interface Props {
    list: object
}

const KeyValueList: FunctionComponent<Props> = ({ list }) => {
    return (
        <div className="my-2">
            { renderList(list)}
        </div>
    )
}

const renderList = (list: object) => {

    const entries = Object.entries(list); // Iterate over the object to build an array of key-value pairs

    let outputList = entries.map((entry, index) =>
        renderListElement(index, entry[0], entry[1])
    );

    return outputList;

}

function renderListElement(index: number, key: string, value: string) {
    return (
        <div className="mb-3" key={index}>
            <div className="text-gray-600 text-xs">
                {key}
            </div>
            <div className="text-gray-800 text-lg font-semibold">
                {value}
            </div>
        </div>
    );
}

export default KeyValueList;
