import React, {
  FunctionComponent,
  useState,
  useEffect,
} from 'react'

import './../../styles/PageContentTransition.css'

interface Props {
  loadDirection: string
}

const PageContentTransition: FunctionComponent<Props> = ({ loadDirection, children }) => {

  const [animationClass, setAnimationClass] = useState('')

  //depending on the value of the loadDirection prop
  //set the CSS class on the div returned by this component
  useEffect(() => {
    if (loadDirection === 'PUSH') {
      setAnimationClass('slide-in-right')
    } else if (loadDirection === 'POP') {
      setAnimationClass('slide-in-left')
    }
  }, [animationClass, loadDirection])

  return (
    <div className={animationClass}>
      { children}
    </div>
  )
}

export default PageContentTransition