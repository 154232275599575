import { customerState, Constants } from "../types/customerTypes";

const initialState: customerState = {
  orgName: "",
  orgLogo: null,
  roofName: "",
  reviewURLs: [],
};

export default function customerReducer(state = initialState, action: any) {
  switch (action.type) {
    case Constants.GET_ORG_AND_ROOF_INFO:
      const { organization, rooftop, review_urls } = action.payload;
      return {
        ...state,
        orgName: organization.name,
        orgLogo: organization.logo,
        roofName: rooftop.name,
        reviewURLs: review_urls,
      };

    default:
      return state;
  }
}
