import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Edit3, Plus } from 'react-feather';

import { connect } from 'react-redux';
import { ReduxState } from '../../../redux/store';
import { roofObject } from '../../../redux/types/orgTypes';
import { Dispatch, Action } from 'redux';
import { getOrgRoofs } from '../../../redux/actions/orgActions';

import Nav from '../../../components/Nav';
import Card from '../../../components/Card';
import Pill from '../../../components/Pill';
import SearchBar from '../../../components/SearchBar';

const mapStateToProps = ({ user, org }: ReduxState) => {
    const { orgId } = user
    const { orgRoofs } = org
    return { orgId, orgRoofs }
}

const mapDipsatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        getOrgRoofs: (orgId: number) => dispatch(getOrgRoofs(orgId))
    }
}

interface Props {
    orgId: number
    orgRoofs: roofObject[]
    getOrgRoofs: Function
}

const Rooftops: FunctionComponent<Props> = ({ orgId, orgRoofs, getOrgRoofs }) => {

    //state
    const [searchInput, setSearch] = useState('')
    const [filteredRoofs, setFilteredRoofs] = useState(orgRoofs)

    useEffect(() => {
        getOrgRoofs(orgId)
    }, [])

    //initalize the filteredRoofs array with the orgRoofs array
    useEffect(() => {
        setFilteredRoofs(orgRoofs)
    }, [orgRoofs])

    //upon search input, filter through the orgRoofs array
    //and set the result as the filteredRoofs array
    useEffect(() => {
        setFilteredRoofs(
            orgRoofs.filter(rooftop => rooftop.roofName.toLowerCase().includes(searchInput.toLowerCase()))
        )
    }, [searchInput])

    //create list of rooftops as green pills
    const rooftopList = filteredRoofs.map(rooftop => {
        return (
            <Link to={`/manage-rooftop/${orgId}/${rooftop.roofId}`} key={rooftop.roofId}>
                <Pill
                    color={'bg-ozRepGreen'}
                    textColor={'text-white'}
                    shadow={false}>
                    <p>{rooftop.roofName}</p>
                    <Edit3 />
                </Pill>
            </Link>

        )
    })
    return (
        <div>
            <Nav
                backButtonExists={true}
                middleExists={false}
                buttonText={''}
                buttonLink={''}
                handleButtonClick={() => false}
                adminHomeExists={true}
                logoIsLink={true}
            />
            <Card title={'Locations'}>

                <div className="grid grid-cols-1 row-auto gap-y-6">

                    <Link to="/create-rooftop">
                        <Pill
                            color={'bg-ozRepGray'}
                            textColor={'text-black'}
                            shadow={true}>
                            <p>Create A New Location</p>
                            <Plus />
                        </Pill>
                    </Link>

                    <SearchBar
                        value={searchInput}
                        placeholder={'Search by location name...'}
                        onInput={setSearch}
                    />

                    {rooftopList}
                </div>

            </Card>
        </div>
    )
}

export default connect(mapStateToProps, mapDipsatchToProps)(Rooftops);