import React, { FunctionComponent, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Edit3, Plus } from 'react-feather';

import { connect } from 'react-redux';
import { ReduxState } from '../../../redux/store';
import { Dispatch, Action } from 'redux';
import { getOrgUsers } from '../../../redux/actions/orgActions';
import { userObject } from '../../../redux/types/orgTypes';

import Nav from '../../../components/Nav';
import Card from '../../../components/Card';
import SearchBar from '../../../components/SearchBar';
import Pill from '../../../components/Pill';

const mapStateToProps = ({ org, user }: ReduxState) => {
    const { orgUsers } = org
    const { orgId } = user
    return { orgUsers, orgId }
}

const mapDipsatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        getOrgUsers: (orgId: number) => dispatch(getOrgUsers(orgId)),
    }
}

interface Props {
    orgId: number
    orgUsers: userObject[]
    getOrgUsers: Function
}

const Users: FunctionComponent<Props> = ({ orgId, orgUsers, getOrgUsers }) => {

    //state
    const [searchInput, setSearch] = useState('')
    const [filteredUsers, setFilteredUsers] = useState(orgUsers)

    useEffect(() => {
        getOrgUsers(orgId)
    }, [])

    //initalize the filteredUsers array with the orgUsers array
    useEffect(() => {
        setFilteredUsers(orgUsers)
    }, [orgUsers])

    //upon search input, filter through the orgUsers array
    //and set the result as the filteredUsers array
    useEffect(() => {
        setFilteredUsers(
            orgUsers.filter(user => user.userName.toLowerCase().includes(searchInput.toLowerCase()))
        )
    }, [searchInput])

    //create list of users as green pills
    const userList = filteredUsers.map(user => {
        return (
            <Link to={`/manageuser/${user.userOrgId}/${user.userId}`} key={user.userId}>
                <Pill
                    color={'bg-ozRepGreen'}
                    textColor={'text-white'}
                    shadow={false}>
                    <p>{user.userName}</p>
                    <Edit3 />
                </Pill>
            </Link>

        )
    })

    return (
        <div>
            <Nav
                backButtonExists={true}
                middleExists={false}
                buttonText={''}
                buttonLink={''}
                handleButtonClick={() => false}
                adminHomeExists={true}
                logoIsLink={true}
            />
            <Card title={'Users'}>

                <div className="grid grid-cols-1 row-auto gap-y-6">

                    <Link to="/create-user">
                        <Pill
                            color={'bg-ozRepGray'}
                            textColor={'text-black'}
                            shadow={true}>
                            <p>Create A New User</p>
                            <Plus />
                        </Pill>
                    </Link>

                    <SearchBar
                        value={searchInput}
                        placeholder={'Search by user name...'}
                        onInput={setSearch}
                    />

                    {userList}
                </div>

            </Card>

        </div>
    )
}

export default connect(mapStateToProps, mapDipsatchToProps)(Users);
