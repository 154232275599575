import React, { FunctionComponent } from 'react';
import BackgroundPagePosition from './components/layout/background-page-position';
import AppRouter from './AppRouter';

import './styles/PageBackground.css';

const App: FunctionComponent = () => {

  return (
    <BackgroundPagePosition>

      <AppRouter />

    </BackgroundPagePosition>

  )
}

export default App;


