import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Smile, User, MapPin, Users, Cpu } from 'react-feather';

import { connect } from 'react-redux';
import { ReduxState } from '../../redux/store';
import { Dispatch, Action } from 'redux';
import { logoutUser } from '../../redux/actions/authActions';
import { getOrgUsers } from '../../redux/actions/orgActions';

import Nav from '../../components/Nav';
import Card from '../../components/Card';
import Pill from '../../components/Pill';

const mapStateToProps = ({ user }: ReduxState) => {
    const { orgId } = user
    return { orgId }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        getOrgUsers: (orgId: number) => dispatch(getOrgUsers(orgId)),
        logoutUser: () => dispatch(logoutUser())
    }
}

interface Props {
    orgId: number
    getOrgUsers: Function
    logoutUser: Function
}

const AdminHome: FunctionComponent<Props> = ({ orgId, getOrgUsers, logoutUser }) => {

    const handleLogoutButton = () => {
        logoutUser()
    }

    return (
        <div>
            <Nav
                backButtonExists={true}
                middleExists={true}
                buttonText={'Logout'}
                buttonLink={''}
                handleButtonClick={handleLogoutButton}
                adminHomeExists={false}
                logoIsLink={true}
            />
            <Card title={'Administration'}>

                <div className="grid grid-cols-1 row-auto gap-y-6">

                    <Link to='/reviews'>
                        <Pill
                            color={'bg-ozRepGreen'}
                            textColor={'text-white'}
                            shadow={true}>
                            <p>Negative Reviews</p>
                            <Smile />
                        </Pill>
                    </Link>

                    <Link to='/configuration'>
                        <Pill
                            color={'bg-white'}
                            textColor={'text-black'}
                            shadow={true}>
                            <p>My Organization</p>
                            <User />
                        </Pill>
                    </Link>

                    <Link to='/rooftops'>
                        <Pill
                            color={'bg-white'}
                            textColor={'text-black'}
                            shadow={true}>
                            <p>Locations</p>
                            <MapPin />
                        </Pill>
                    </Link>

                    <Link to='/users'>
                        <Pill
                            color={'bg-white'}
                            textColor={'text-black'}
                            shadow={true}>
                            <p>Users</p>
                            <Users />
                        </Pill>
                    </Link>


                    <Link to='/api-tokens'>
                        <Pill
                            color={'bg-white'}
                            textColor={'text-black'}
                            shadow={true}>
                            <p>API Tokens</p>
                            <Cpu />
                        </Pill>
                    </Link>

                </div>
            </Card>

        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
