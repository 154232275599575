import { OzRepAPI } from '../OzRepAPIClient';
import { Constants } from '../types/authTypes';
import { Constants as UserConstants } from '../types/userTypes';
import { Constants as NotificationConstants } from '../types/notificationTypes';
import { showNotification } from '../actions/notificationActions'
import { Dispatch } from 'redux';

export function loginUser(email: string, password: string): any {
    return function (dispatch: Dispatch) {

        return OzRepAPI.post('/account/login', { email, password })
            .then(res => {
                //put user token on session storage and redux state 
                const token = res.data.token

                localStorage.setItem('token', token)
                localStorage.setItem('user', JSON.stringify(res.data.user))

                dispatch({ type: Constants.LOGIN_USER, payload: token })
                dispatch({ type: UserConstants.SET_USER, payload: res.data.user })
                dispatch(showNotification(NotificationConstants.SHOW_SUCCESS, "Successfully logged in"))

            })
            .catch(err => {
                dispatch(showNotification(NotificationConstants.SHOW_ERROR, "These credentials don't match our records"))
            })
    }
}

export function logoutUser(): any {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    window.location.href = '/'
}
