import React, { FunctionComponent, ButtonHTMLAttributes } from 'react';

interface Props {
    type?: ButtonHTMLAttributes<HTMLButtonElement>
    buttonText: string
}

const ButtonSubmit: FunctionComponent<Props> = ({ buttonText }) => {

    return (
        <div className="grid grid-cols-2" >
            <button className="bg-ozRepGreen col-start-2 flex justify-center items-center rounded-full text-white p-4 text-xl font-semibold shadow cursor-pointer focus:outline-none" type="submit">
                {buttonText}
            </button>
        </div>
    )
}

export default ButtonSubmit;