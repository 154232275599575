import React, { FunctionComponent } from 'react';

const Footer: FunctionComponent = () => {
    return (
        <div className="mb-2">
            <div className="push h-8"></div>
            <footer className="flex-shrink-0 w-full flex justify-center items-center">
                <div data-testid="footer-content" className="bg-white p-4 rounded-white flex justify-center items-center text-ozRepGreen h-6 rounded-full shadow">
                    <a href="https://docs.ozreputation.com/releases/changelog" target="_BLANK" rel="noopener noreferrer" className="pr-2">v{process.env.REACT_APP_VERSION}</a>
                    |
                    <a href="https://forms.monday.com/forms/63cdbfc47e6ce588cba376222b23e395" target="_BLANK" rel="noopener noreferrer" className="pl-2">Support</a> </div>
            </footer>
        </div>

    )
}

export default Footer;



