import React, { FunctionComponent } from 'react';
import { Search } from 'react-feather';

interface Props {
    placeholder: string
    value?: string
    onInput: Function
}

const SearchBar: FunctionComponent<Props> = ({ placeholder, value, onInput }) => {
    const setValue = (value: number | string | null) => {
        onInput(value)
    }

    return (
        <div className="flex h-12 w-full rounded-full bg-ozRepGray shadow-inner py-2 pl-4">
            <input
                type="text"
                placeholder={placeholder}
                onChange={e => setValue(e.target.value)}
                className="bg-ozRepGray italic outline-none min-w-0 flex-1"
            />
            <div className="text-textGray p-2 transform rotate-90">
                <Search />
            </div>
        </div>
    )
}

export default SearchBar;