import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';

import { CheckCircle, AlertCircle, AlertTriangle } from 'react-feather'

import './../../styles/Notification.css'

export const NotificationBar: FunctionComponent = () => {

    // Listen on redux state for changes in NotificationState
    const { notificationType, notificationMessage } : any = useSelector(
        (state: ReduxState) => state.notification
    )

    const [backgroundColor, setBackgroundColor] = useState('')
    const [textColor, setTextColor] = useState('')
    const [icon, setIcon] = useState('')
    const [display, setDisplay] = useState('hidden')

    function setNotificationState(bgColor : string, textColor : string, icon : string, display : boolean) {
        setBackgroundColor(bgColor)
        setTextColor(textColor)
        setIcon(icon)
        setDisplay(display ? 'block' : 'hidden')
    }

    function renderIcon(icon : string) {
        switch(icon) {
            case('check-circle'):
                return <CheckCircle />

            case('alert-circle'):
                return <AlertCircle />

            case('alert-triangle'):
                return <AlertTriangle />

            case(''):
                return ""

            default: 
                break;
        }
    }

    useEffect(() => {
        // Choose the icon and the css classes to use based on the notification type
        switch (notificationType) {
            case ("success"):
                setNotificationState('ozRepGreen', 'white', 'check-circle', true)
                break
                
            case ("error"):
                setNotificationState('red-600', 'white', 'alert-circle', true)
                break

            case ("warning"):
                setNotificationState('orange-500', 'white', 'alert-triangle', true)
                break

            case (""): // Match on no notification, or on CLEAR_NOTIFICATION
                setNotificationState('transparent', 'transparent', '', false)
                break;

            default:
                break
        }
    }, [notificationType, notificationMessage])        

    return (
        <div data-testid="notification-content" className={ `w-full py-2 flex justify-center items-center rounded-t-lg text-center fixed bottom-0 text-${textColor} bg-${backgroundColor} ${display} slideUpFromBottom` }>
            {renderIcon(icon)}
            <div className="mx-2">{notificationMessage}</div>
        </div>
    )
}
