import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import authReducer from './reducers/authReducer';
import { authState } from './types/authTypes';

import userReducer from './reducers/userReducer';
import { userState } from './types/userTypes';

import orgReducer from './reducers/orgReducer';
import { orgState } from './types/orgTypes';

import developerReducer from './reducers/developerReducer';
import { developerState } from './types/developerTypes';

import customerReducer from './reducers/customerReducer';
import { customerState } from './types/customerTypes';

import reviewReducer from './reducers/reviewReducer';
import { ReviewsState } from './types/reviewTypes';

import notificationReducer from './reducers/notificationReducer';
import { NotificationState } from './types/notificationTypes';

export interface ReduxState {
    auth: authState
    user: userState
    org: orgState
    dev: developerState
    customer: customerState
    reviews: ReviewsState
    notification: NotificationState
}

const reducers = combineReducers({
    auth: authReducer,
    user: userReducer,
    org: orgReducer,
    dev: developerReducer,
    customer: customerReducer,
    reviews: reviewReducer,
    notification: notificationReducer
})

const store = createStore<ReduxState, any, any, any>(reducers, composeWithDevTools(applyMiddleware(thunk)));

export default store;