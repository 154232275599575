import React, { FunctionComponent } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { ArrowLeft, Home } from 'react-feather';

interface Props {
    backButtonExists: boolean
    middleExists: boolean
    buttonText: string
    buttonLink: string
    handleButtonClick: Function
    adminHomeExists: boolean
    logoIsLink: boolean
}

const Nav: FunctionComponent<Props> = ({ backButtonExists, middleExists, buttonText, buttonLink, handleButtonClick, adminHomeExists, logoIsLink }) => {
    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }

    //styling constants
    const backBtn = "bg-white p-3 rounded-lg shadow cursor-pointer h-full mr-2"

    const adminHomeBtn = "bg-white p-3 rounded-lg shadow cursor-pointer"

    const middleBtn = "bg-white h-full px-4 rounded-lg shadow font-bold cursor-pointer text-center flex items-center"

    const navLogo = "nav-logo bg-white rounded-md shadow flex justify-center items-center "

    const handleClick = () => {
        handleButtonClick()
    }

    /*Determine whether the middle button will be a Link or a button*/
    const determineMiddleButton = () => {
        if (buttonLink) {
            return (
                <div className="">
                    <Link to={buttonLink}>
                        <div
                            data-testid='nav-middle-btn'
                            className={middleBtn}>
                            {buttonText}
                        </div>
                    </Link>
                </div>
            )
        } else if (handleButtonClick) {
            return (
                <div className="">
                    <div
                        data-testid='nav-middle-btn'
                        className={middleBtn}
                        onClick={handleClick}>
                        {buttonText}
                    </div>
                </div>

            )
        }
    }

    return (
        <div className="flex justify-between my-2 px-3 w-full h-12">
            <div className="flex">

                {/*Back Button */}
                {backButtonExists ?
                    <div
                        className={backBtn}
                        onClick={goToPreviousPath}>
                        <div data-testid='nav-back-btn' className="fill-current text-ozRepGreen"><ArrowLeft /></div>
                    </div>
                    :
                    <div></div>
                }

                {/*AdminHome quick access button */}
                {adminHomeExists ?
                    <Link to="/admin-home">
                        <div className={adminHomeBtn}>
                            <div className="fill-current text-ozRepGreen"><Home /></div>
                        </div>
                    </Link>
                    :
                    <div></div>
                }
            </div>

            {/*Middle Button */}
            {middleExists ?
                determineMiddleButton()
                :
                <div></div>
            }

            {/*Logo */}
            {logoIsLink ?
                <div className={navLogo}>
                    <Link to='/home'>
                        <img src="/oz-rep-logo.png" alt="Oz Rep Logo" className="p-2" />
                    </Link>
                </div>
                :
                <div className={navLogo}>
                    <img src="/oz-rep-logo.png" alt="Oz Rep Logo" className="p-2" />
                </div>
            }

        </div>
    )
}

export default Nav;
