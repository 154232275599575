import { OzRepAPI } from '../OzRepAPIClient';
import { Constants } from '../types/orgTypes';
import { Dispatch } from 'redux';

//retrieve information about an organization
export function getOrgData(orgId: number): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.get(`/v2/organizations/${orgId}`)
            .then(res => {
                const orgData = res.data.organization
                dispatch({ type: Constants.GET_ORG_DATA, payload: orgData })
            })
            //what error handling methods should we use?
            .catch(err => console.log(err))
    }
}

//retrieve all rooftops in an organization
export function getOrgRoofs(orgId: number): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.get(`/v2/organizations/${orgId}/rooftops`)
            .then(res => {
                const orgRoofs = res.data.rooftops
                dispatch({ type: Constants.GET_ORG_ROOFS, payload: orgRoofs })
            })
            //what error handling methods should we use?
            .catch(err => console.log(err))
    }
}

//retrieve all users in an organization
export function getOrgUsers(orgId: number): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.get(`/v2/organizations/${orgId}/users`)
            .then(res => {
                const orgUsers = res.data.users
                dispatch({ type: Constants.GET_ORG_USERS, payload: orgUsers })
            })
            //what error handling methods should we use?
            .catch(err => console.log(err))
    }
}


/**---------NOTE-----------------**
 * The functions below are not mapped to component props
 * like other redux actions.
 * Instead, they are imported and invoked like regular
 * helper functions.
 *  This is to allow the component to handle the promise
 * returned by the function at its local level and set
 * the needed values from the return data on local component
 * state
 */

//retrieve information about a rooftop in an organization
export function getOrgRoof(orgId: number, roofId: number): any {
    return OzRepAPI.get(`/v2/organizations/${orgId}/rooftops/${roofId}`)
}

//retrieve information about a user in an organization
export function getOrgUser(orgId: number, userId: number): any {
    return OzRepAPI.get(`/v2/organizations/${orgId}/users/${userId}`)
}
