import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { ReduxState } from '../../redux/store';
import { Dispatch, Action } from 'redux';
import { getOrgAndRoofInfo } from '../../redux/actions/customerActions';

import Nav from '../../components/Nav';
import Card from '../../components/Card';
import OrgLogo from '../../components/OrgLogo';

const mapStateToProps = ({ customer }: ReduxState) => {
  const { orgName, orgLogo, roofName } = customer
  return { orgName, orgLogo, roofName }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    getOrgAndRoofInfo: (requestId: number) => dispatch(getOrgAndRoofInfo(requestId))
  }
}

interface Props {
  orgName: string,
  orgLogo: string | null,
  roofName: string,
  getOrgAndRoofInfo: Function
}

const Thanks: FunctionComponent<Props> = ({ orgName, orgLogo, roofName, getOrgAndRoofInfo }) => {

  const params: any = useParams()
  const token: number = params.token

  useEffect(() => {
    if (!orgName && !orgLogo && !roofName) {
      getOrgAndRoofInfo(token)
    }
  })

  return (
    <div>

      <Nav
        backButtonExists={false}
        middleExists={false}
        buttonText=''
        buttonLink=''
        handleButtonClick={() => false}
        adminHomeExists={false}
        logoIsLink={false}
      />

      <Card title={'Thank You!'}>

        <div className="grid grid-cols-1 row-auto gap-y-6">

          <OrgLogo
            orgName={orgName}
            rooftopName={roofName}
            logoSrc={orgLogo} />

          <div>
            <h1 className="font-bold text-xl text-center">Thank you!</h1>
            <p className="m-8">Your feedback has been sent to our quality assurance team. We appreciate the time you've taken to help us ensure that we provide an exceptional customer experience.</p>
          </div>

        </div>
      </Card>

    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Thanks);

