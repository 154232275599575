
export interface orgState {
    orgData: orgObject,
    orgRoofs: roofObject[],
    orgUsers: userObject[],
}

export interface orgObject {
    orgId: number | null,
    orgName: string,
    orgLogo: string | null
}

export interface roofObject {
    roofId: number | null,
    roofName: string,
    roofLogo: string | null,
}

export interface userObject {
    userId: number | null,
    userName: string,
    userEmail: string,
    userRole: string,
    userOrgId: number | null
}

export enum Constants {
    //----------------------MVP--------------//
    //orgActions                             //
    GET_ORG_DATA = "GET_ORG_DATA",           //
    GET_ORG_ROOFS = "GET_ORG_ROOFS",         //
    GET_ORG_USERS = "GET_ORG_USERS",         //
    //----------------------MVP--------------//

    //adminReviewActions
    GET_REVIEWS = "GET_REVIEWS",
    GET_REVIEW_DETAILS = "GET_REVIEW_DETAILS",
    GET_REVIEW_REQUESTS = "GET_REVIEW_REQUESTS",
    GET_USER_REQUESTS = "GET_USERS_REQUESTS",

    //adminOrgActions
    CREATE_ORG = "CREATE_ORG",
    UPDATE_ORG = "UPDATE_ORG",
    DEACTIVATE_ORG = "DEACTIVATE_ORG",

    //owner actions
    UPDATE_BILLING_INFO = "UPDATE_BILLING_INFO"

}