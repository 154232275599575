import React, { FunctionComponent } from 'react';

export interface Props {
    labelText: string
    options: string[]
    value?: string
    onChange: Function
    convex?: boolean // changes the background color and shadow to give the appearance of a convex pill
}

const Dropdown: FunctionComponent<Props> = ({ labelText, options, value, convex, onChange }) => {
    const handleUserSelect = (event: any) => {
        if (labelText.includes("Permission")){
            onChange(event.target.value)
        }
        else{
            onChange(labelText,event.target.value)
        }
        
    }

    const optionsList = options.map((option, index) => {
        return <option key={index} value={option} className="h-12 w-full rounded-full bg-ozRepGray shadow-inner outline-none pl-3 capitalize">{option}</option>
    })

    return (
        <div>
            <label>{labelText}</label>
            <select
                className={'dropdown h-12 w-full rounded-full outline-none px-3 capitalize ' + (convex ? 'bg-white shadow' : 'bg-ozRepGray shadow-inner')}
                onChange={handleUserSelect}
                value={value}>
                {optionsList}
            </select>
        </div>
    )
}

export default Dropdown;
