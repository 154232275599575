import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Repeat } from 'react-feather';

import { connect } from 'react-redux';
import { ReduxState } from '../redux/store';
import { roofObject } from '../redux/types/orgTypes';
import { Dispatch, Action } from 'redux';
import { setUser } from '../redux/actions/userActions';
import { getOrgRoofs, getOrgData } from '../redux/actions/orgActions';

import Nav from '../components/Nav';
import Card from '../components/Card';
import SearchBar from '../components/SearchBar';
import Pill from '../components/Pill';
import OrgLogo from '../components/OrgLogo';

const mapStateToProps = ({ user, org }: ReduxState) => {
    const { defaultStore, userId, orgId } = user
    const { orgRoofs } = org
    const { orgName, orgLogo } = org.orgData
    return { defaultStore, orgRoofs, userId, orgId, orgName, orgLogo }
}

const mapDipsatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setUser: (userId: number, orgId: number, data: any) => dispatch(setUser(orgId, userId, data)),
        getOrgRoofs: (orgId: number) => dispatch(getOrgRoofs(orgId)),
        getOrgData: (orgId: number) => dispatch(getOrgData(orgId))
    }
}

interface Props {
    defaultStore: number,
    orgRoofs: roofObject[],
    userId: number,
    orgId: number,
    orgName: string,
    orgLogo: string
    setUser: Function,
    getOrgRoofs: Function,
    getOrgData: Function,
}

const ChangeStore: FunctionComponent<Props> = ({ defaultStore, orgRoofs, userId, orgId, orgName, orgLogo, setUser, getOrgRoofs, getOrgData }) => {

    const history: any = useHistory()

    //state
    const [searchInput, setSearch] = useState('')
    const [storeName, setStoreName] = useState('')
    const [filteredStores, setFilteredStores] = useState(orgRoofs)

    useEffect(() => {
        getOrgRoofs(orgId)
        getOrgData(orgId)
    }, [])

    //find and set the default location's name
    useEffect(() => {
        if (defaultStore) {
            const [rooftop] = orgRoofs.filter(roof => defaultStore === roof.roofId)
            if (!rooftop) {
                setStoreName('')
            } else {
                setStoreName(rooftop.roofName)
            }
        }
    }, [orgRoofs, defaultStore])

    //initialize the filteredStores array with the orgRoofs array
    useEffect(() => {
        setFilteredStores(orgRoofs)
    }, [orgRoofs])

    //upon a search input, filter through the orgRoofs array
    //and set the result as the filteredStores array
    useEffect(() => {
        setFilteredStores(
            orgRoofs.filter(store => store.roofName.toLowerCase().includes(searchInput.toLowerCase()))
        )
    }, [searchInput])

    //change the current default location 
    const handlePillClick = (store: roofObject) => {
        if (!defaultStore) {
            const data = { default_store: store.roofId }
            setUser(orgId, userId, data)
                .then((res: any) => history.push('/request-review'))
                .catch((err: any) => console.log(err))

        }
        const data = { default_store: store.roofId }
        setUser(orgId, userId, data)
    }

    //create list of stores as green pills
    const storeList = filteredStores.map(store => {
        return (

            <div
                data-testid="store-pill"
                onClick={() => handlePillClick(store)}
                key={store.roofId}>
                <Pill
                    color={'bg-ozRepGreen'}
                    textColor={'text-white'}
                    shadow={false}>
                    <p>{store.roofName}</p>
                    <Repeat />
                </Pill>
            </div>
        )
    })

    return (
        <div>
            <Nav
                backButtonExists={true}
                middleExists={false}
                buttonText={''}
                buttonLink={''}
                handleButtonClick={() => false}
                adminHomeExists={false}
                logoIsLink={true}
            />
            <Card title={'Select Your Location'}>

                <div className="grid grid-cols-1 row-auto gap-6">

                    <fieldset className="border-solid border-2 border-ozRepGreen rounded-lg">
                        <legend className="text-ozRepGreen bg-white, text-xs mx-4 px-1">Current Default</legend>
                        <OrgLogo
                            orgName={orgName}
                            rooftopName={storeName}
                            logoSrc={orgLogo ? orgLogo : ''} />
                    </fieldset>

                    <SearchBar
                        value={searchInput}
                        placeholder={'Search by location name...'}
                        onInput={setSearch}
                    />

                    <div
                        data-testid="store-list"
                        className="grid grid-cols-1 row-auto gap-6">{storeList}
                    </div>

                </div>

            </Card>

        </div>
    )
}

export default connect(mapStateToProps, mapDipsatchToProps)(ChangeStore);
