import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { ReduxState } from '../redux/store';
import { Dispatch, Action } from 'redux';
import { logoutUser } from '../redux/actions/authActions';
import { setUser, getMe } from '../redux/actions/userActions';

//NOT mapped to props via connect function
import { getOrgRoof } from '../redux/actions/orgActions';

import Nav from '../components/Nav';
import Card from '../components/Card';
import Form from '../components/Form'
import Input from '../components/Input';
import ButtonSubmit from '../components/ButtonSubmit';
import PillCurrentDefault from '../components/PillCurrentDefault';

const mapStateToProps = ({ user }: ReduxState) => {
    const { defaultStore, orgId, userId, userName, userEmail } = user
    return { defaultStore, orgId, userId, userName, userEmail }
}

const mapDipsatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setUser: (userId: number, orgId: number, data: any) => dispatch(setUser(orgId, userId, data)),
        logoutUser: () => dispatch(logoutUser()),
        getMe: () => dispatch(getMe())
    }
}
interface Props {
    defaultStore: number,
    orgId: number,
    userId: number,
    userName: string,
    userEmail: string,
    setUser: Function,
    getMe: Function,
    logoutUser: Function
}

const Profile: FunctionComponent<Props> = ({ defaultStore, orgId, userId, userEmail, userName, setUser, getMe, logoutUser }) => {

    useEffect(() => {
        getMe()
    }, [])

    //state
    const [name, setName] = useState(userName)
    const [email, setEmail] = useState(userEmail)
    const [storeName, setStoreName] = useState('No Location Selected')

    //fetch the default location's name to display
    useEffect(() => {
        if (defaultStore) {
            getOrgRoof(orgId, defaultStore)
                .then((res: any) => {
                    const name = res.data.rooftop.name
                    setStoreName(name)
                })
                //what error handling methods should we use?
                .catch((err: any) => {
                    //error messaging
                })
        }
    }, [])

    //events
    const handleFormSubmit = () => {
        let data = { name, email }
        setUser(orgId, userId, data)
    }

    const handleLogoutButton = () => {
        logoutUser()
    }

    return (
        <div>
            <Nav
                backButtonExists={true}
                middleExists={true}
                buttonText={'Logout'}
                buttonLink={''}
                handleButtonClick={handleLogoutButton}
                adminHomeExists={false}
                logoIsLink={true}
            />
            <Card title={'My Profile'}>

                <Form submitForm={handleFormSubmit}>
                    <Input
                        labelText="Name"
                        inputType="text"
                        value={name}
                        onChange={setName} />

                    <Input
                        labelText="Email"
                        inputType="text"
                        inputMode="email"
                        value={email}
                        onChange={setEmail} />


                    <Link to="/change-location">
                        <PillCurrentDefault pillText={storeName ? storeName : 'No Location Selected'} />
                    </Link>

                    <ButtonSubmit
                        buttonText={'Update'}
                    />
                </Form>

                <Link
                    to='/change-password'>
                    <h3 className="text-ozRepGreen text-center underline text-lg mt-6">Change Password</h3>
                </Link>
            </Card>
        </div>
    )
}

export default connect(mapStateToProps, mapDipsatchToProps)(Profile);
