import React, { FunctionComponent } from 'react';

interface Props {
    orgName: string,
    rooftopName: string,
    logoSrc: string | null
}

const OrgLogo: FunctionComponent<Props> = ({ orgName, rooftopName, logoSrc }) => {

    return (
        <div className="m-5 text-black font-rokkitt text-center flex flex-col justify-center items-center">
            {/* Show rooftopName if it's given, otherwise default to text*/}
            {rooftopName ?
                <p className="font-bold text-3xl">{rooftopName}</p>
                :
                <p className="mb-2">No Location Selected</p>
            }

            <hr className="w-1/3 border-black"></hr>
            {/* Show Org logo if it's given, otherwise default to org name */}
            {logoSrc ?
                <img src={logoSrc} alt={orgName} className="org-logo" />
                :
                <p className="text-lg">{orgName}</p>
            }
        </div>
    )
}

export default OrgLogo;
