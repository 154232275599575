import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { loginUser } from '../redux/actions/authActions';
import { showNotification } from '../redux/actions/notificationActions'
import { Constants } from '../redux/types/notificationTypes'

import Form from '../components/Form';
import Input from '../components/Input';
import ButtonSubmit from '../components/ButtonSubmit';
import Footer from '../components/Footer';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    loginUser: (email: string, password: string) => dispatch(loginUser(email, password)),
    showNotification: (notificationType: string, notificationMessage: string) => dispatch(
      showNotification(notificationType, notificationMessage)
    )
  }
}

interface Props {
  loginUser: Function
  showNotification: Function
}

const Login: FunctionComponent<Props> = ({ loginUser, showNotification }) => {

  let history: any = useHistory()

  //check if there is a token in localStorage
  //if there is, the user is logged in
  //if there is not, the user is not logged in
  useEffect(() => {
    checkIfLoggedIn()
  })

  const checkIfLoggedIn = () => {
    if (localStorage.getItem('token')) {
      history.push('/home')
    }
  }

  //state
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  //events
  const handleFormSubmit = async () => {
    if (email === '' || password === '') {
      showNotification(Constants.SHOW_WARNING, 'Email and password are required')
    } else {
      await loginUser(email, password)
      checkIfLoggedIn()
    }
  }

  return (
    <div className="p-4 grid grid-cols-1 row-auto gap-y-4">

      {/*Logo*/}
      <div className="bg-white rounded-md shadow m-2 flex justify-center items-center mx-auto">
        <img alt="Oz Reputation" src="/oz-rep-logo.png" className="p-4" />
      </div>

      {/*Form*/}
      <div className="bg-white rounded-lg shadow p-6 gap-32">
        <Form submitForm={handleFormSubmit}>

          <Input
            labelText='Email'
            inputType='email'
            inputMode='email'
            value={email}
            onChange={setEmail} />

          <Input
            labelText='Password'
            inputType='password'
            value={password}
            onChange={setPassword} />

          <ButtonSubmit
            buttonText='Login'
          />

          <Link to='/forgot-password'>
            <p className="text-ozRepGreen text-right underline text-lg">Forgot Password?</p>
          </Link>

        </Form>
      </div>

      <Footer />

    </div>
  )
}

export default connect(null, mapDispatchToProps)(Login);


