
export interface userState {
    userId: number | null,
    userName: string,
    userEmail: string,
    userRole: string,
    orgId: number | null,
    defaultStore: number | null,
}
export interface userOrg {
    orgId: number | null,
    orgName: string
}

export enum Constants {
    SET_USER = "SET_USER"
}