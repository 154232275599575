import React, { FunctionComponent, useState } from 'react';

interface Props {
    height?: string
    labelText?: string
    inputMode?: any
    value?: string
    placeholderText?: string
    name: string
    maxLength: number
    onChange: Function
}

const Input: FunctionComponent<Props> = ({ height, labelText, inputMode = 'text', name, value, placeholderText, maxLength, onChange }) => {

    const [charCount, setCount] = useState(0)

    const setValue = (value: string) => {
        const chars = value.length
        setCount(chars)

        onChange(value)
    }

    return (
        <div>
            <label>
                {labelText}
                <textarea
                    name={name}
                    value={value}
                    placeholder={placeholderText}
                    inputMode={inputMode}
                    maxLength={maxLength}
                    className={`h-${height ? height : "36"} p-4 bg-ozRepGray w-full shadow-inner rounded-lg focus:outline-none`}
                    onChange={e => setValue(e.target.value)}
                ></textarea>
            </label>
            <p className="text-right">{charCount}/{maxLength}</p>
        </div>
    )

    
}

export default Input;
