import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Smile, MapPin, User, Key } from 'react-feather';

import { connect } from 'react-redux';
import { ReduxState } from '../redux/store';
import { Dispatch, Action } from 'redux';
import { getMe } from '../redux/actions/userActions';
import { showNotification } from '../redux/actions/notificationActions';
import { Constants } from '../redux/types/notificationTypes';

import Pill from '../components/Pill';
import Footer from '../components/Footer';

const mapStateToProps = ({ user }: ReduxState) => {
  const { userRole, orgId, defaultStore } = user
  return { userRole, orgId, defaultStore }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    getMe: () => dispatch(getMe()),
    showNotification: (notificationType: string, notificationMessage: string, clearDelay: number) => dispatch(showNotification(notificationType, notificationMessage, clearDelay))
  }
}

interface Props {
  userRole: string,
  orgId: number,
  defaultStore: number,
  getMe: Function,
  showNotification: Function
}

const Home: FunctionComponent<Props> = ({ userRole, getMe, defaultStore, showNotification }) => {

  useEffect(() => {
    getMe()

    //show a warning notification if they do not have a default location set
    if (defaultStore === null || typeof defaultStore === 'undefined') {
      const message = "Please go to Change My location to set your default location"
      //set the clear time to one week in seconds, effectively
      //making it so this notification never clears until they
      //set a default location
      showNotification(Constants.SHOW_WARNING, message, 604800)
    }
  })

  return (
    <div className="flex flex-col">

      <div className="flex-grow flex-shrink flex-auto p-4 grid grid-cols-1 row-auto gap-12 mb-8">

        {/*Logo */}
        <div className="bg-white rounded-md shadow m-2 flex justify-center items-center mx-auto">
          <Link to='/'><img alt="OzReputation" src="/oz-rep-logo.png" className="p-4" /></Link>
        </div>

        {/*Options list */}
        <div className="grid grid-cols-1 row-auto gap-y-5">
          <Link to='/request-review'>
            <Pill
              color={'bg-ozRepGreen'}
              textColor={'text-white'}
              shadow={true}>
              <p>Request a Review</p>
              <Smile />
            </Pill>
          </Link>

          <Link to='/change-location'>
            <Pill
              color={'bg-white'}
              textColor={'text-black'}
              shadow={true}>
              <p>Change My Location</p>
              <MapPin />
            </Pill>
          </Link>

          <Link to='/profile'>
            <Pill
              color={'bg-white'}
              textColor={'text-black'}
              shadow={true}>
              <p>My Profile</p>
              <User />
            </Pill>
          </Link>

          {/*only render this option if authorized as admin or owner */}
          {userRole === 'admin' || userRole === 'owner' ?
            <Link to='/admin-home'>
              <Pill
                color={'bg-white'}
                textColor={'text-black'}
                shadow={true}>
                <p>Admin</p>
                <Key />
              </Pill>
            </Link>
            : null}
        </div>
      </div>

      <Footer />

    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);


