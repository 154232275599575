import React, { Component } from 'react';

type ToggleableFilterState = {
    active: boolean
}

export interface ToggleableFilterProps {
    active: boolean
    labelText: string
    onToggle: Function
}

export class ToggleableFilter extends Component<ToggleableFilterProps, ToggleableFilterState> {

    constructor(props: ToggleableFilterProps) {
        super(props);

        this.state = {
            active: this.props.active
        }

        // Why on earth do I have to do this? Why doesn't *this* just work
        // like any other language?
        this.handleToggle = this.handleToggle.bind(this);
    }

    render() {

        let variableStyle: string = '';

        if (this.state.active) {
            variableStyle += ' text-white bg-ozRepGreen shadow-inner';
        } else {
            variableStyle += ' bg-white text-gray-600 shadow';
        }

        return (
            <div
                className={`rounded-full capitalize p-2 w-24 text-center 
                    text-sm font-semibold ${variableStyle}`}
                onClick={this.handleToggle}
            >
                {this.props.labelText}
            </div>
        )
    }

    handleToggle() {
        this.setState((state) => ({
            active: !state.active
        }));
        this.props.onToggle(this.props.labelText, !this.state.active);
    }
}
