import { OzRepAPI } from "../../OzRepAPIClient";
import { getOrgUsers } from "../orgActions";
import { Constants as notificationConsts } from "../../types/notificationTypes";
import { showNotification } from "../notificationActions";
import { Dispatch } from "redux";

export function updateUserAsAdmin(
  userId: number,
  orgId: number,
  user: any
): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.put(`/v2/organizations/${orgId}/users/${userId}`, { user })
      .then((res) => {
        const message = "User updated";
        dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message));
      })
      .catch((err) => {
        const message = "Error updating user";
        dispatch(showNotification(notificationConsts.SHOW_ERROR, message));
      });
  };
}

export function createUser(
  orgId: number,
  userName: string,
  userEmail: string,
  userPassword: string,
  userRole: string
): any {
  return function (dispatch: Dispatch) {
    const body = {
      name: userName,
      email: userEmail,
      password: userPassword,
      role: userRole,
    };
    OzRepAPI.post(`/v2/organizations/${orgId}/users`, body)
      .then((res) => {
        dispatch(getOrgUsers(orgId));
        const message = "User created";
        dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message));
      })
      .catch((err) => {
        const message = "Failed to create user";
        dispatch(showNotification(notificationConsts.SHOW_ERROR, message));
      });
  };
}

export function deactivateUser(orgId: number, userId: number): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.delete(`/v2/organizations/${orgId}/users/${userId}`)
      .then((res) => {
        dispatch(getOrgUsers(orgId));
        const message = "User deleted";
        dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message));
      })
      .catch((err) => {
        const message = "Failed to delete user";
        dispatch(showNotification(notificationConsts.SHOW_ERROR, message));
      });
  };
}
