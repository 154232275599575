import React, { FunctionComponent } from 'react';

interface Props {
    color: string
    textColor: string
    shadow: boolean
    emphasized?: boolean
}

const Pill: FunctionComponent<Props> = ({ color, textColor, shadow, emphasized = true, children }) => {

    return (
        <div data-testid="pill-content" className={`h-12 w-full flex justify-between items-center rounded-full cursor-pointer p-3 ${shadow ? 'shadow' : ''} ${emphasized ? `${color} ${textColor}` : 'bg-white text-textGray border border-textGray'}`}>
            {children}
        </div>
    )
}

export default Pill;
