import { OzRepAPI } from '../../OzRepAPIClient';
import { Constants } from '../../types/orgTypes';
import { Constants as notificationConsts } from '../../types/notificationTypes';
import { showNotification } from '../notificationActions';
import { Dispatch } from 'redux';
import { getOrgData } from '../orgActions';

export function updateOrgName(orgId: number, organization: object): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.patch(`/v2/organizations/${orgId}`, { organization })
            .then(res => {
                dispatch(getOrgData(orgId))

            })
            .catch(err => {

            })
    }
}

export function updateOrgLogo(orgId: number, data: any): any {
    return function (dispatch: Dispatch) {
        OzRepAPI.put(`/v2/organizations/${orgId}/logo`, data)
            .then(res => {
                dispatch(getOrgData(orgId))

            })
            .catch(err => {

            })
    }
}
