import
  React,
  {
    FunctionComponent,
    useEffect,
    useState,
    useRef,
    useLayoutEffect,
  }
  from 'react'

import { useHistory } from 'react-router-dom'
import { NotificationBar } from './notification-bar'

import './../../styles/template.css'

const BackgroundPagePosition : FunctionComponent = ({ children }) => {

  /**
   * Watch router page history to determine if the page location has changed.
   * 
   * Use that information to either increment or decrement the
   * background-position.
   */
  let history : any = useHistory()
  const bgRef = useRef<HTMLDivElement>(null)
  const [lastPage, setLastPage] : [string, any] = useState("")
  useEffect(() => {
    return history.listen((location : any, action : string) => {

      // Ensure that no animation occurs if the navigation
      // goes to the same page.
      if (lastPage !== location.pathname) {

        // Navigate forward
        if (action === 'PUSH') {
          setBgPos((bgPos : number) : number => {
            return bgPos + 1
          })

          // Navigate back
        } else if(action === 'POP') {
          setBgPos((bgPos : number) : number => {
            return bgPos - 1
          })
        }
      }

      setLastPage(location.pathname)
    })
  },[history, lastPage])

  /**
   * Watch for changes on the bgPos variable. If any change
   * has occured multiply by 100 and set
   * background-position equal to
   * the result viewport width.
   * 
   * This results in a 100vw page transition for the background.
   */
  const [bgPos, setBgPos] : [number, any] = useState(0)
  useLayoutEffect(() => {
    if (window.innerWidth >= 600) return // For large screens, don't animate the background
    if (bgRef && bgRef.current) {
      bgRef.current.style.backgroundPositionX = `-${bgPos * 100}vw`
    }
  }, [bgPos])

  return (
    <div className="page-background flex justify-center" ref={ bgRef }>

      <div id="page-content-container"> 
        { children }
      </div>

      <NotificationBar/>

    </div>
  )
}

export default BackgroundPagePosition