import React, { FunctionComponent } from 'react';

interface Props {
    submitForm: Function
    encType?: string
}

const Form: FunctionComponent<Props> = ({ submitForm, encType, children }) => {

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        submitForm()
    }

    return (
        <form className="grid grid-cols-1 row-auto gap-y-6" onSubmit={handleSubmit} encType={encType}>
            {children}
        </form>
    )
}

export default Form;