import React, { FunctionComponent, useEffect, useState } from 'react';
import { Clipboard } from 'react-feather';

import { connect } from 'react-redux';
import { ReduxState } from '../../../redux/store';
import { tokenObject } from '../../../redux/types/developerTypes';
import { Dispatch, Action } from 'redux';
import { getDevTokens, createDevToken, clearNewTokenValue, revokeDevToken } from '../../../redux/actions/developerActions';
import { showNotification } from '../../../redux/actions/notificationActions';
import { Constants } from '../../../redux/types/notificationTypes';

import Nav from '../../../components/Nav';
import Card from '../../../components/Card';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import ButtonSubmit from '../../../components/ButtonSubmit';
import Button from '../../../components/Button';
import APITokenList from './APITokenList';

const mapStateToProps = ({ user, dev }: ReduxState) => {
    const { orgId, userId } = user
    const { devTokens, newTokenValue } = dev
    return { orgId, userId, devTokens, newTokenValue }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        getDevTokens: (orgId: number) => dispatch(getDevTokens(orgId)),
        createDevToken: (orgId: number, token: object) => dispatch(createDevToken(orgId, token)),
        clearNewTokenValue: () => dispatch(clearNewTokenValue()),
        revokeDevToken: (orgId: number, tokenId: number) => dispatch(revokeDevToken(orgId, tokenId)),
        showNotification: (notificationType: string, notificationMessage: string) => dispatch(showNotification(notificationType, notificationMessage))
    }
}

interface Props {
    orgId: number
    userId: number
    devTokens: tokenObject[]
    newTokenValue: string
    getDevTokens: Function
    createDevToken: Function
    clearNewTokenValue: Function
    revokeDevToken: Function
    showNotification: Function
}

const CreateUser: FunctionComponent<Props> = ({ orgId, userId, devTokens, newTokenValue, getDevTokens, createDevToken, clearNewTokenValue, revokeDevToken, showNotification }) => {

    //state
    const [tokenName, setTokenName] = useState('')
    const [displayToken, setDisplayToken] = useState('')

    useEffect(() => {
        getDevTokens(orgId)
    }, [])

    useEffect(() => {
        const truncated = newTokenValue.slice(0, 25)
        setDisplayToken(truncated)

        //clean up function to clear
        //the redux state of newTokenValue
        return () => {
            if (newTokenValue) {
                clearNewTokenValue()
            }
        }
    }, [newTokenValue])

    //events
    const handleFormSubmit = () => {
        const api_token = { name: tokenName }
        createDevToken(orgId, api_token)
    }

    const handleCopyToken = () => {
        navigator.clipboard.writeText(newTokenValue)
            .then(() => {
                const message = "Copied to clipboard"
                showNotification(Constants.SHOW_SUCCESS, message)
            })
            .catch(() => {
                const message = "Failed to create token"
                showNotification(Constants.SHOW_ERROR, message)
            })
    }

    const handleTokenRevoke = (tokenId: number) => {
        if (window.confirm('Are you sure you want to revoke this token?')) {
            revokeDevToken(orgId, tokenId)
        }
    }

    return (
        <div>
            <Nav
                backButtonExists={true}
                middleExists={false}
                buttonText={''}
                buttonLink={''}
                handleButtonClick={() => false}
                adminHomeExists={true}
                logoIsLink={true}
            />
            <Card title={'API Tokens'}>
                <h1 className='text-black text-xl font-semibold mb-2'>
                    Create New Token
                </h1>
                <Form submitForm={handleFormSubmit}>
                    <Input
                        labelText="New Token Name"
                        inputType="text"
                        value={tokenName}
                        onChange={setTokenName} />

                    <ButtonSubmit
                        buttonText="Create Token"
                    />
                </Form>

                {displayToken ?
                    <div className="grid grid-cols-1 row-auto gap-y-6">
                        <div className="flex flex-col items-center border-solid border-2 border-ozRepGreen p-2 mt-8">
                            <h3 className="text-ozRepGreen text-xl font-bold mb-2">{tokenName}</h3>
                            <div
                                className="h-12 w-full rounded-full flex justify-between items-center p-3 mb-2 cursor-pointer bg-ozRepGray shadow-inner"
                                onClick={handleCopyToken}
                            >
                                <p>{displayToken}...</p>
                                <Clipboard onClick={handleCopyToken} />
                            </div>
                        </div>
                        <Button
                            buttonText="Copy"
                            onClick={handleCopyToken}
                        />

                    </div>
                    :
                    <div className="hidden"></div>
                }

                {devTokens ?
                    <div>
                        <h1 className='text-black text-xl font-semibold my-2'>
                            Existing Tokens
                        </h1>
                        <APITokenList
                            devTokens={devTokens}
                            revoke={handleTokenRevoke}
                        />
                    </div>
                    :
                    <div className="hidden"></div>
                }

            </Card>

        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
