import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom';
import Card from '../components/Card';

const NotFound: FunctionComponent = () => {
  return (
      <Card title={'Not Found'}>

        <div className="grid grid-cols-1 row-auto row-gap-6">

          <div>
            <h1 className="font-bold text-xl text-center">Page Not Found</h1>
            <p className="m-8">Looks like the page you were looking for doesn't exist.</p>
            <div className="text-ozRepGreen text-center w-full underline">
              <Link to="/home">Head Back Home</Link>
            </div>
          </div>

        </div>
      </Card>
  )
}

export default NotFound;

