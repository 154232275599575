import React, { useState, useEffect, FunctionComponent } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import PageContentTransition from "./components/layout/page-content-transition";

//user pages
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import RequestReview from "./pages/RequestReview";
import ChangeStore from "./pages/ChangeStore";
import ResetPassword from "./pages/ResetPassword";

//admin pages
import AdminHome from "./pages/admin/AdminHome";
import Reviews from "./pages/admin/Reviews/Reviews";
import ManageReview from "./pages/admin/Reviews/ManageReview";
import EmailReview from "./pages/admin/Reviews/EmailReview";
import OrgConfig from "./pages/admin/Organizations/OrgConfig";
import Rooftops from "./pages/admin/Rooftops/Rooftops";
import CreateRooftop from "./pages/admin/Rooftops/CreateRooftop";
import ManageRooftop from "./pages/admin/Rooftops/ManageRooftop";
import Users from "./pages/admin/Users/Users";
import ManageUser from "./pages/admin/Users/ManageUser";
import CreateUser from "./pages/admin/Users/CreateUser";
import APITokens from "./pages/admin/API Tokens/APITokens";

//customer pages
import Rate from "./pages/customer/Rate";
import Platforms from "./pages/customer/Platforms";
import Feedback from "./pages/customer/Feedback";
import Thanks from "./pages/customer/Thanks";
import ProtectedRoute from "./components/protected-routes/ProtectedRoute";

//other pages
import NotFound from "./pages/NotFound";

const Router: FunctionComponent = () => {
  let history: any = useHistory();
  const [routerAction, setRouterAction] = useState("");

  //listen to the router history to determine what action it
  //most recently performed and set that action to routerAction state
  //routerAction state will then be used set the loadDirection prop on
  //the PageContentTransition component that wraps each page in the Switch below
  useEffect(() => {
    return history.listen((location: any, action: string) => {
      setRouterAction(action);
    });
  }, [routerAction, history]);

  return (
    <Switch>
      {/*-----ROUTES FOR USER PAGES-----*/}
      <Route exact path="/">
        <PageContentTransition key={0} loadDirection={routerAction}>
          <Login />
        </PageContentTransition>
      </Route>
      <Route path="/forgot-password">
        <PageContentTransition key={1} loadDirection={routerAction}>
          <ForgotPassword />
        </PageContentTransition>
      </Route>
      <ProtectedRoute path="/home" admin={false}>
        <PageContentTransition key={2} loadDirection={routerAction}>
          <Home />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/profile" admin={false}>
        <PageContentTransition key={3} loadDirection={routerAction}>
          <Profile />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/change-password" admin={false}>
        <PageContentTransition key={4} loadDirection={routerAction}>
          <ChangePassword />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/request-review" admin={false}>
        <PageContentTransition key={5} loadDirection={routerAction}>
          <RequestReview />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/change-location" admin={false}>
        <PageContentTransition key={6} loadDirection={routerAction}>
          <ChangeStore />
        </PageContentTransition>
      </ProtectedRoute>
      <Route path="/reset-password/:token">
        <PageContentTransition key={7} loadDirection={routerAction}>
          <ResetPassword />
        </PageContentTransition>
      </Route>

      {/*-----ROUTES FOR ADMIN PAGES-----*/}
      <ProtectedRoute path="/admin-home" admin={true}>
        <PageContentTransition key={8} loadDirection={routerAction}>
          <AdminHome />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/reviews" admin={true}>
        <PageContentTransition key={9} loadDirection={routerAction}>
          <Reviews />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/manage-review/:reviewId" admin={true}>
        <PageContentTransition key={9} loadDirection={routerAction}>
          <ManageReview />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/email-review/:reviewId" admin={true}>
        <PageContentTransition key={9} loadDirection={routerAction}>
          <EmailReview />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/configuration" admin={true}>
        <PageContentTransition key={12} loadDirection={routerAction}>
          <OrgConfig />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/rooftops" admin={true}>
        <PageContentTransition key={13} loadDirection={routerAction}>
          <Rooftops />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/create-rooftop" admin={true}>
        <PageContentTransition key={14} loadDirection={routerAction}>
          <CreateRooftop />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/manage-rooftop/:orgId/:roofId" admin={true}>
        <PageContentTransition key={15} loadDirection={routerAction}>
          <ManageRooftop />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/users" admin={true}>
        <PageContentTransition key={16} loadDirection={routerAction}>
          <Users />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/manageuser/:orgId/:userId" admin={true}>
        <PageContentTransition key={17} loadDirection={routerAction}>
          <ManageUser />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/create-user" admin={true}>
        <PageContentTransition key={18} loadDirection={routerAction}>
          <CreateUser />
        </PageContentTransition>
      </ProtectedRoute>
      <ProtectedRoute path="/api-tokens" admin={true}>
        <PageContentTransition key={19} loadDirection={routerAction}>
          <APITokens />
        </PageContentTransition>
      </ProtectedRoute>

      {/*-----ROUTES FOR CUSTOMER PAGES-----*/}
      <Route path="/rate/:token">
        <PageContentTransition key={20} loadDirection={routerAction}>
          <Rate />
        </PageContentTransition>
      </Route>
      <Route path="/review/:token">
        <PageContentTransition key={21} loadDirection={routerAction}>
          <Platforms />
        </PageContentTransition>
      </Route>
      <Route path="/feedback/:token">
        <PageContentTransition key={22} loadDirection={routerAction}>
          <Feedback />
        </PageContentTransition>
      </Route>
      <Route path="/thanks/:token">
        <PageContentTransition key={23} loadDirection={routerAction}>
          <Thanks />
        </PageContentTransition>
      </Route>

      {/*-----DEFAULT ROUTE-----*/}
      <Route>
        <PageContentTransition key={24} loadDirection={routerAction}>
          <NotFound />
        </PageContentTransition>
      </Route>
    </Switch>
  );
};

export default Router;
