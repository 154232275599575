import React, { FunctionComponent, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { ReduxState } from "../../../redux/store";
import { Dispatch, Action } from "redux";
import { ReviewObject } from "../../../redux/types/reviewTypes";
import { roofObject } from "../../../redux/types/orgTypes";
import { getOrgRoofs } from "../../../redux/actions/orgActions";
import { getReviews } from "../../../redux/actions/adminActions/adminReviewActions";
import { useHistory } from 'react-router-dom';

import Nav from "../../../components/Nav";
import Card from "../../../components/Card";
import InnerCard from "../../../components/InnerCard";
import KeyValueList from "../../../components/KeyValueList";
import ManageReviewControls from "./ManageRControls";

const mapStateToProps = ({ user, org, reviews }: ReduxState) => {
  const { orgId } = user;
  const orgReviews = reviews.reviews;
  const { orgRoofs } = org;
  return { orgId, orgReviews, orgRoofs };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    getOrgRoofs: (orgId: number) => dispatch(getOrgRoofs(orgId)),
    getReviews: (orgId: number) => dispatch(getReviews(orgId)),
  };
};

interface Props {
  orgId: number;
  orgReviews: ReviewObject[];
  orgRoofs: roofObject[];
  getOrgRoofs: Function;
  getReviews: Function;
}

const ManageReview: FunctionComponent<Props> = ({
  orgId,
  orgReviews,
  orgRoofs,
  getOrgRoofs,
  getReviews,
}) => {
  const params: any = useParams();

  const [review, setReview]: any = useState({});
  const [flaggedState, setFlaggedState] = useState(false);
  const [resolvedState, setResolvedState] = useState(false);
  const [innerCardProps, setInnerCardProps] = useState({
    title: "",
    emphasized: true,
    flagged: false,
  });
  const [keyValueListObject, setKeyValueListObject]: any = useState({});
  const history: any = useHistory()

  useEffect(() => {
    getOrgRoofs(orgId);
    getReviews(orgId);
  }, []);

  useEffect(() => {
    findReview();
  }, [orgReviews]);

  const findReview = () => {
    const [review] = orgReviews.filter(
      (review) => review.id === +params.reviewId
    );
    if (!review){
      history.push('/admin-home')
    }
    setReview(review);
  };

  useEffect(() => {
    if (review) {
      setInnerCardProps({
        title: review.phoneNum,
        emphasized: review.emphasized,
        flagged: review.flagged,
      });
      setFlaggedState(review.flagged);
      setResolvedState(review.resolved);
      createKeyValueListObject();
    }
  }, [review]);

  const cleanNums = () => {
    if (review) {
      let cleaned = ("" + review.phoneNum).replace(/\D/g, "");
      if (cleaned[0] !== "1") {
        cleaned = `+1` + cleaned;
      } else {
        cleaned = "+" + cleaned;
      }
      return cleaned;
    }
    return "";
  };
  const createKeyValueListObject = () => {
    let object: any = {
      "Review Text": review.message,
    };

    const [rooftop] = orgRoofs.filter((roof) => roof.roofId === review.roofId);
    if (rooftop) {
      let reviewLocationName = rooftop.roofName;
      object["Location"] = reviewLocationName;
    } else {
      object["Location"] = "Unavailable";
    }
    console.log(review)
    if (review.date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      console.log(review)
      const reviewDate = new Date(review.date).toLocaleDateString(
        undefined,
        //options,
        
      );
      object["Date Recieved"] = reviewDate;
    } else {
      object["Date Recieved"] = "Unavailable";
    }

    if (review.name) {
      object["Associated Name"] = review.name;
    } else {
      object["Associated Name"] = "None Given";
    }

    if (review.wantsResponse) {
      object["Wants Resoponse"] = "Yes";
      object["Response Sent"] = review.responseSent ? "Yes" : "No";
    } else {
      object["Wants Response"] = "No";
    }

    if (review.resolved) {
      object["Status"] = "Resolved";
    } else {
      object["Status"] = "Unresolved";
    }

    setKeyValueListObject(object);
  };

  return (
    <div>
      <Nav
        backButtonExists={true}
        middleExists={false}
        buttonText={""}
        buttonLink={""}
        handleButtonClick={() => false}
        adminHomeExists={true}
        logoIsLink={true}
      />
      <Card title={"Manage Review"}>
        <InnerCard
          title={innerCardProps.title}
          emphasized={innerCardProps.emphasized}
          flagged={innerCardProps.flagged}
        >
          <KeyValueList list={keyValueListObject} />
        </InnerCard>

        <ManageReviewControls
          reviewId={+params.reviewId}
          orgId={orgId}
          flaggedState={flaggedState}
          resolvedState={resolvedState}
          phone={cleanNums()}
        />
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageReview);
