import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OzRepAPI } from "../../../redux/OzRepAPIClient";

import { connect } from "react-redux";
import { ReduxState } from "../../../redux/store";
import { Dispatch, Action } from "redux";
import { createUser } from "../../../redux/actions/adminActions/adminUserActions";
import {
  showNotification,
  clearNotification,
} from "../../../redux/actions/notificationActions";
import { Constants } from "../../../redux/types/notificationTypes";

import Nav from "../../../components/Nav";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Input from "../../../components/Input";
import Dropdown from "../../../components/Dropdown";
import ButtonSubmit from "../../../components/ButtonSubmit";

const mapStateToProps = ({ user }: ReduxState) => {
  const { orgId } = user;
  return { orgId };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    createUser: (
      orgId: number,
      userName: string,
      userEmail: string,
      userPassword: string,
      userRole: string
    ) =>
      dispatch(createUser(orgId, userName, userEmail, userPassword, userRole)),
    showNotification: (
      notificationType: string,
      notificationMessage: string,
      clearDelay: number
    ) =>
      dispatch(
        showNotification(notificationType, notificationMessage, clearDelay)
      ),
    clearNotification: () => dispatch(clearNotification()),
  };
};

interface Props {
  orgId: number;
  createUser: Function;
  showNotification: Function;
  clearNotification: Function;
}

const CreateUser: FunctionComponent<Props> = ({
  orgId,
  createUser,
  showNotification,
  clearNotification,
}) => {
  const history: any = useHistory();

  //state
  const [userName, setName] = useState("");
  const [userEmail, setEmail] = useState("");
  const [userPassword, setPassword] = useState("");
  const [userRole, setRole] = useState("user");
  const [error, setError] = useState("");

  useEffect((): any => {
    //componentWillUnmount
    return () => {
      clearNotification();
    };
  }, []);

  //events
  const handleFormSubmit = async () => {
    //check the users in the current org
    //if any have a matching email address, show a warning the user
    //that the email address is already taken.
    const users = await OzRepAPI.get(`/v2/organizations/${orgId}/users`);
    const match = users.data.users.filter(
      (user: any) => user.email === userEmail
    );

    if (match.length > 0) {
      const message = "Email address already taken.";
      setError(message);
      showNotification(Constants.SHOW_WARNING, message, 30000);
    } else {
      createUser(orgId, userName, userEmail, userPassword, userRole);
      history.push("/users");
    }
  };

  return (
    <div>
      <Nav
        backButtonExists={true}
        middleExists={false}
        buttonText={""}
        buttonLink={""}
        handleButtonClick={() => false}
        adminHomeExists={true}
        logoIsLink={true}
      />
      <Card title={"Create New User"}>
        <Form submitForm={handleFormSubmit}>
          <Input
            labelText="Name"
            inputType="text"
            value={userName}
            onChange={setName}
          />

          <Input
            labelText="Email"
            inputType="text"
            inputMode="email"
            value={userEmail}
            onChange={setEmail}
          />
          {error && <p className="-mt-5 text-red-500">{error}</p>}

          <Input
            labelText={"Password"}
            inputType={"password"}
            value={userPassword}
            onChange={setPassword}
          />

          <Dropdown
            labelText="Permissions Level"
            options={["user", "admin", "owner"]}
            value={userRole}
            onChange={setRole}
          />

          <ButtonSubmit buttonText="Create User" />
        </Form>
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
