import { OzRepAPI } from "../OzRepAPIClient";
import { Constants } from "../types/userTypes";
import { Constants as notificationConsts } from "../types/notificationTypes";
import { showNotification } from "../actions/notificationActions";
import { Dispatch } from "redux";
import { logoutUser } from "./authActions";

export function getMe(): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.get(`/v2/me`)
      .then((res) => {
        dispatch({ type: Constants.SET_USER, payload: res.data.user });
      })
      //what error handling methods should we use?
      .catch((err) => logoutUser());
  };
}

export function setUser(userId: number, orgId: number, user: any): any {
  return function (dispatch: Dispatch) {
    return OzRepAPI.put(`/v2/organizations/${orgId}/users/${userId}`, { user })
      .then((res) => {
        dispatch({ type: Constants.SET_USER, payload: res.data.user });
        const message = "User updated";
        dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message));
      })
      .catch((err) => {
        const message = "User failed to update";
        dispatch(showNotification(notificationConsts.SHOW_ERROR, message));
      });
  };
}

export function updateUserPassword(
  orgId: number,
  userId: number,
  password: string
): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.patch(`/v2/organizations/${orgId}/users/${userId}/password`, {
      password,
    })
      .then((res) => {
        const message = "Password updated";
        dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message));
      })
      .catch((err) => {
        const message = "Failed to update password";
        dispatch(showNotification(notificationConsts.SHOW_ERROR, message));
      });
  };
}

//send an email to the user with the link to ResetPassword
export function updateForgottenPassword(email: string): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.post("/account/reset-password-request", { email })
      .then((res) => {
        const message = "Reset email sent";
        dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message));
      })
      .catch((err) => {
        const message = "Failed to send reset email";
        dispatch(showNotification(notificationConsts.SHOW_ERROR, message));
      });
  };
}

export function resetUserPassword(token: string, password: string): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.post(`/account/reset-password/${token}`, { password })
      .then((res) => {
        const message = "Password reset";
        dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message));
      })
      .catch((err) => {
        const message = "Failed to reset password";
        dispatch(showNotification(notificationConsts.SHOW_ERROR, message));
      });
  };
}

export function sendReviewRequest(
  orgId: number,
  roofId: number,
  userId: number,
  review_request: object,
  review_urls: object[]
): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.post(
      `/v2/organizations/${orgId}/rooftops/${roofId}/users/${userId}/reviewrequest`,
      { review_request, review_urls }
    )
      .then((res) => {
        const message = "Request sent";
        dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message));
      })
      .catch((err) => {
        const message = "Failed to send request";
        dispatch(showNotification(notificationConsts.SHOW_ERROR, message));
      });
  };
}
