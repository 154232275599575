import { orgState, Constants } from '../types/orgTypes';

const initialState: orgState = {
    orgData: {
        orgId: null,
        orgName: '',
        orgLogo: null
    },
    orgRoofs: [
        {
            roofId: null,
            roofName: '',
            roofLogo: null
        }],
    orgUsers: [
        {
            userId: null,
            userName: '',
            userEmail: '',
            userRole: '',
            userOrgId: null
        }
    ]
}

export default function orgReducer(state = initialState, action: any) {
    switch (action.type) {
        case Constants.GET_ORG_DATA:
            const { id, logo, name } = action.payload
            const data = {
                orgId: id,
                orgName: name,
                orgLogo: logo
            }
            return { ...state, orgData: data }

        case Constants.GET_ORG_ROOFS:
            let rooftops = action.payload.map((roof: any) => {
                return {
                    roofId: roof.id,
                    roofName: roof.name,
                    roofLogo: roof.logo,
                }
            })

            return { ...state, orgRoofs: rooftops }

        case Constants.GET_ORG_USERS:
            let users = action.payload.map((user: any) => {
                return {
                    userId: user.id,
                    userName: user.name,
                    userEmail: user.email,
                    userRole: user.role,
                    userOrgId: user.organization_id
                }
            })
            return { ...state, orgUsers: users }

        default:
            return state
    }
}