
export interface authState {
    authenticated: boolean,
    token: string
}


export enum Constants {
    LOGIN_USER = "LOGIN_USER",
    LOGOUT_USER = "LOGOUT_USER"
}