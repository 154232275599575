import React, { FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseJwt, isTokenExpired, refreshTokenIfNeeded } from '../../redux/auth-utils';

interface Props {
  path: string,
  admin: boolean
}

const ProtectedRoute: FunctionComponent<Props> = ({ path, admin, children }) => {

  const authenticated = () => {
    // Get the token from localStorage
    const token = localStorage.getItem("token")
    if (!token) {
      return false
    }

    const parsedData = parseJwt(token)
    //refresh time = one week in seconds (604800)
    refreshTokenIfNeeded(token, parsedData)

    // This will redirect
    isTokenExpired(parsedData)

    return true
  }

  //bring in userRole from redux state
  const { userRole } = useSelector((state: any) => {
    return state.user
  })

  //determine if the user is an admin and if the route 
  //requires them to be an admin in order to access it
  const checkIfAdmin = () => {

    let isAdmin;

    if (userRole === 'admin' || userRole === 'owner') {
      isAdmin = true
    } else {
      isAdmin = false
    }

    return isAdmin
  }

  return (
    <>
      {
        admin ? (
          <>
            {authenticated() && checkIfAdmin() ?
              (<Route path={path}>{children}</Route>)
              :
              (<Redirect to="/">{children}</Redirect>)
            }
          </>
        ) : (
            <>
              {authenticated() ?
                (<Route path={path}>{children}</Route>)
                :
                (<Redirect to="/">{children}</Redirect>)
              }
            </>
          )
      }
    </>
  )
}

export default ProtectedRoute;


