import { ReviewObject } from '../../../redux/types/reviewTypes';

export interface SearchAndFilterParams {
    searchInput: string
    bylocation: string
    flagged: boolean
    recent: boolean
    oldest: boolean
    resolved: boolean
}

export const runFilterPipeline: any = (srcOfTruth: ReviewObject[], params: SearchAndFilterParams) => {

    const locationResult: any = byLocation(srcOfTruth, params)
    const flaggedResult: any = flagged(locationResult, params)
    const resolvedResult: any = resolved(flaggedResult, params)
    const finalResult: any = search(resolvedResult, params)
    if (params.recent){
        //params.oldest = false
        return orderByRecent(finalResult,params)
    }
    // if (params.oldest){
    //     return orderByOldest(finalResult, params)
    // }
    else{
        return finalResult
    }
    
}

function byLocation(srcOfTruth: ReviewObject[], params: SearchAndFilterParams) {
    if (params.bylocation == '0') {
        const result = srcOfTruth.filter(obj => obj.roofId > 0 || obj.roofId === null)
        //const result = srcOfTruth;
        return result
    }
    const result = srcOfTruth.filter(obj => obj.roofId === +params.bylocation)
    return result
}

function flagged(filteredTruth: ReviewObject[], params: SearchAndFilterParams) {
    if(params.flagged){
    const result = filteredTruth.filter(obj => obj.flagged === params.flagged)
    return result
    }
    return filteredTruth;
}

function search(filteredTruth: ReviewObject[], params: SearchAndFilterParams) {
    const { searchInput } = params
    if (searchInput) {
        const lowerInput = searchInput.toLowerCase()
        const result: ReviewObject[] = []
        filteredTruth.forEach(obj => {
            if (obj.name) {
                if (obj.name.toLowerCase().includes(lowerInput)) {
                    result.push(obj)
                }
            }
            if (obj.phoneNum) {
                if (obj.phoneNum.includes(lowerInput)) {
                    result.push(obj)
                }
            }
            if (obj.message) {
                if (obj.message.toLowerCase().includes(lowerInput)) {
                    result.push(obj)
                }
            }
        })

        const uniqueResult: ReviewObject[] = result.filter((obj, index) => {
            return result.indexOf(obj) === index
            //indexOf() returns the index of the first occurence of an element in an array
            //so if an identical item in the results array DOES NOT have the same 
            //index as the first occurence, then it is NOT returned from the filter
            //and thus removed from the array leaving us with only unique results
        })
        return uniqueResult
    }
    return filteredTruth
}


function orderByRecent(filteredTruth: ReviewObject[], params: SearchAndFilterParams) {
    let sorted = filteredTruth
    if (params.recent) {
        //TODO
        sorted = filteredTruth.sort((a,b) => {
            return new Date(a.date).getTime() - 
                new Date(b.date).getTime()
        }).reverse();
    }
    return sorted
}

function orderByOldest(filteredTruth: ReviewObject[], params: SearchAndFilterParams) {
    let sorted = filteredTruth
    if (params.oldest) {
        //TODO
        sorted = filteredTruth.sort((a,b) => {
            return new Date(a.date).getTime() - 
                new Date(b.date).getTime()
        });
    }
    return sorted
}

function resolved(filteredTruth: ReviewObject[], params: SearchAndFilterParams) {
    if (params.resolved === true){
    const result = filteredTruth.filter(obj => obj.resolved === params.resolved)
    return result
    }
    return filteredTruth;
}

