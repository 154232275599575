import React, { FunctionComponent } from 'react';
import { Flag } from 'react-feather';

interface Props {
    title: string
    emphasized: boolean // determines the colors and text weight in the card header
    flagged: boolean
}

const InnerCard: FunctionComponent<Props> = ({ title, emphasized, flagged, children }) => {
    return (
        <div>
            <div className="flex-grow flex-shrink flex-auto">

                <div className="my-3">

                    { renderTopTab(flagged) }

                    <div className={ 'relative z-10 text-center text-xl rounded-t-lg p-3 font-semibold ' + ( emphasized ? 'bg-ozRepGreen text-white' : 'text-textGray border-solid border border-textGray bg-white' ) }>
                        {title}
                    </div>
                    <div className={ 'bg-white rounded-b-lg border border-solid shadow p-6 ' + ( emphasized ? 'border-ozRepGreen' : 'border-textGray' ) }>
                        {children}
                    </div>
                </div>

            </div>
        </div>
    )
}

const renderTopTab = (flagged: boolean) => {
    if (flagged) {
        return ( 
            <div className={ 'inner-card relative top-2 pb-4 flex justify-center items-center text-md text-white rounded-t-lg p-2 font-semibold bg-red-700' }>
                <Flag color="#FFFFFF" />
                <div className="mx-1"></div>
                <p>Flagged</p> 
            </div> 
        )
    }
}

export default InnerCard;
