export interface customerState {
  orgName: string;
  orgLogo: string | null;
  roofName: string;
  reviewURLs: Platform[];
}

export interface Platform {
  name: string;
  url: string;
  enabled: boolean;
}

export enum Constants {
  GET_ORG_AND_ROOF_INFO = "GET_ORG_AND_ROOF_INFO",
}
