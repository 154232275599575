import React, { FunctionComponent, useState } from 'react';

import { connect } from 'react-redux';
import { ReduxState } from '../redux/store';
import { Dispatch, Action } from 'redux';
import { logoutUser } from '../redux/actions/authActions';
import { updateUserPassword } from '../redux/actions/userActions';
import { showNotification } from '../redux/actions/notificationActions';
import { Constants } from '../redux/types/notificationTypes';

import Nav from '../components/Nav';
import Card from '../components/Card';
import Form from '../components/Form';
import Input from '../components/Input';
import ButtonSubmit from '../components/ButtonSubmit';

const mapStateToProps = ({ user }: ReduxState) => {
    const { orgId, userId, userEmail } = user
    return { orgId, userId, userEmail }
}

const mapDipsatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        updateUserPassword: (orgId: number, userId: number, password: string) => dispatch(updateUserPassword(orgId, userId, password)),
        logoutUser: () => dispatch(logoutUser()),
        showNotification: (notificationType: string, notificationMessage: string) => dispatch(showNotification(notificationType, notificationMessage))
    }
}

interface Props {
    orgId: number,
    userId: number,
    userEmail: string,
    updateUserPassword: Function,
    logoutUser: Function,
    showNotification: Function
}

const ChangePassword: FunctionComponent<Props> = ({ orgId, userId, userEmail, updateUserPassword, logoutUser, showNotification }) => {

    //state
    const [newPass, setPassword] = useState('')
    const [confirmPass, setConfirm] = useState('')

    //events
    const handleFormSubmit = () => {
        if (newPass === confirmPass) {
            updateUserPassword(orgId, userId, confirmPass)
        } else {
            const message = "Passwords must match"
            showNotification(Constants.SHOW_WARNING, message)
        }
    }

    const handleLogoutButton = () => {
        logoutUser()
    }

    return (
        <div>
            <Nav
                backButtonExists={true}
                middleExists={true}
                buttonText={'Logout'}
                buttonLink={''}
                handleButtonClick={handleLogoutButton}
                adminHomeExists={false}
                logoIsLink={true}
            />
            <Card title={'Change Password'}>

                <div className="text-ozRepGreen text-center text-sm mb-4">
                    <p>Change password for:</p>
                    <p data-testid="user-email">{userEmail}</p>
                </div>

                <Form submitForm={handleFormSubmit}>

                    <Input
                        labelText="New Password"
                        inputType="password"
                        value={newPass}
                        onChange={setPassword} />

                    <Input
                        labelText="Confirm Password"
                        inputType="password"
                        value={confirmPass}
                        onChange={setConfirm} />

                    <ButtonSubmit
                        buttonText={'Update'}
                    />

                </Form>
            </Card>

        </div>
    )
}

export default connect(mapStateToProps, mapDipsatchToProps)(ChangePassword);
