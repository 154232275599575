import React, { FunctionComponent } from 'react';
import { X } from 'react-feather';

import { tokenObject } from '../../../redux/types/developerTypes';

import InnerCard from '../../../components/InnerCard';
import KeyValueList from '../../../components/KeyValueList';

interface Props {
    devTokens: tokenObject[]
    revoke: Function
}

const APITokenList: FunctionComponent<Props> = ({ devTokens, revoke }) => {

    const activeTokens = devTokens.filter(token => token.active)
    const revokedTokens = devTokens.filter(token => !token.active)

    const activeList = activeTokens.map(token => {
        const options = { year: "numeric", month: "long", day: "numeric" }
        const created = new Date(token.inserted_at).toLocaleDateString(undefined, options)

        const keyValueList = { "Created At": created, "Revoked At": "Not Applicable" }
        return (
            <InnerCard
                key={token.id}
                title={token.name}
                emphasized={true}
                flagged={false}
            >
                <div className="flex items-center justify-between">

                    <div>
                        <KeyValueList list={keyValueList} />
                    </div>

                    <div
                        className="flex flex-col items-center justify-center"
                        onClick={() => revoke(token.id)}
                    >
                        <h4 className="text-xs">Revoke</h4>
                        <div className="h-10 w-full flex items-center justify-center bg-red-700 text-white text-center rounded-md">
                            <X />
                        </div>
                    </div>

                </div>
            </InnerCard>
        )
    })

    const revokedList = revokedTokens.map(token => {
        const options = { year: "numeric", month: "long", day: "numeric" }
        const created = new Date(token.inserted_at).toLocaleDateString(undefined, options)
        const revoked = new Date(token.updated_at).toLocaleDateString(undefined, options)

        const keyValueList = { "Created At": created, "Revoked At": revoked }
        return (
            <InnerCard
                key={token.id}
                title={token.name}
                emphasized={false}
                flagged={false}
            >
                <KeyValueList list={keyValueList} />
            </InnerCard>
        )
    })

    return (
        <div>
            {activeList}
            {revokedList}
        </div>
    )
}

export default APITokenList;