/**
 * Helper functions for authentication,
 * JWT parsing, and protected route redirects
 */

import { OzRepAPI } from './OzRepAPIClient';

// set to one week (in seconds)
//const refreshTime = 604800

const refreshTokenIfNeeded = (token, parsedData) => {
  // before they are logged out... 
  if (Math.floor(Date.now() / 1000) > parsedData.exp) {
    OzRepAPI.post('/account/refresh', {
      token: token,
    }).then(res => {
      localStorage.setItem("token", res.data.token)
    }).catch(err => {
      resetAuthState()
    })
  }
}

const isTokenExpired = (parsedData) => {
  // They are logged out. Time for a new token.
  if (Math.floor(Date.now() / 1000) > parsedData.exp) {
    resetAuthState()
  }
}

const resetAuthState = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  window.location.href = '/'
}

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};

export { parseJwt, resetAuthState, isTokenExpired, refreshTokenIfNeeded }
