import React, { FunctionComponent, useState, useEffect } from "react";
import { runFilterPipeline, SearchAndFilterParams } from "./filter-pipeline";

import { connect } from "react-redux";
import { ReduxState } from "../../../redux/store";
import { Dispatch, Action } from "redux";
import { ReviewObject } from "../../../redux/types/reviewTypes";
import { getOrgRoofs } from "../../../redux/actions/orgActions";
import { getReviews } from "../../../redux/actions/adminActions/adminReviewActions";
import { roofObject } from "../../../redux/types/orgTypes";

import Nav from "../../../components/Nav";
import Card from "../../../components/Card";
import SearchableFilterableList from "../../../components/lists/SearchableFilterableList";

const mapStateToProps = ({ user, reviews, org }: ReduxState) => {
  const { orgId } = user;
  const orgReviews = reviews.reviews;
  const { orgRoofs } = org;
  return { orgId, orgReviews, orgRoofs };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    getOrgRoofs: (orgId: number) => dispatch(getOrgRoofs(orgId)),
    getReviews: (orgId: number) => dispatch(getReviews(orgId)),
  };
};

interface Props {
  orgId: number;
  orgReviews: ReviewObject[];
  orgRoofs: roofObject[];
  getOrgRoofs: Function;
  getReviews: Function;
}

const Reviews: FunctionComponent<Props> = ({
  orgId,
  orgReviews,
  orgRoofs,
  getOrgRoofs,
  getReviews,
}) => {
  const [filteredReviews, setFilteredReviews]: any = useState([]);
  const [unresolvedReviews, setUnresolved] = useState([]);
  const [resolvedReviews, setResolved] = useState([]);

  useEffect(() => {
    getOrgRoofs(orgId);
    getReviews(orgId);
  }, []);

  //initialize the list of reviews with ALL the reviews
  useEffect(() => {
    setFilteredReviews(orgReviews);
  }, [orgReviews]);

  //watch the filteredReviews list and if it changes
  //reset the review groups
  useEffect(() => {
    setReviewGroups();
  }, [filteredReviews]);

  const setReviewGroups = () => {
    //if a review IS emphasized, it means it will appear as a GREEN pill because it HAS NOT been resolved and needs attention
    const unresolved = filteredReviews.filter(
      (review: any) => review.resolved === false
    );

    //if a review IS NOT emphasized, it means it will appear as a GRAY pill because it HAS been resolved and does not need attention
    const resolved = filteredReviews.filter(
      (review: any) => review.resolved === true
    );

    setUnresolved(unresolved);
    setResolved(resolved);
  };

  const handleSearchOrFilter = (params: SearchAndFilterParams) => {
    //initialize a "new" params object in order to change/update it
    let newParams = { ...params };

    //pull the rooftop name off of the "old" params from the
    //SearchableFilterableList component
    const { bylocation } = params;

    //change the rooftop name to a rooftop id
    //and set bylocation to that id value as string
    //on the "new" params object

    if (bylocation === "Show All") {
      newParams.bylocation = "0";
    } else {
      const [location] = orgRoofs.filter(
        (roof) => roof.roofName === bylocation
      );
      newParams.bylocation = `${location.roofId}`;
    }

    //run the filter pipeline with the full list of reviews on redux state and the "new" params object
    const results: ReviewObject[] = runFilterPipeline(orgReviews, newParams);

    //set the filteredList state value of this component with the results
    if (results) {
      setFilteredReviews(results);
    } else {
      setFilteredReviews([]);
    }
  };

  const handleInfiniteScroll = () => {};

  const roofOptions = orgRoofs.map((roof) => roof.roofName);

  return (
    <div>
      <Nav
        backButtonExists={true}
        middleExists={false}
        buttonText={""}
        buttonLink={""}
        handleButtonClick={() => false}
        adminHomeExists={true}
        logoIsLink={true}
      />
      <Card title={"Negative Reviews"}>
        <SearchableFilterableList
          onSearchOrFilter={handleSearchOrFilter}
          initialSearchAndFilterState={{
            searchInput: "",
            bylocation: "Show All",
            flagged: false,
            recent: true,
            //oldest: false,
            resolved: false,
          }}
          onInfiniteScrollTriggered={handleInfiniteScroll}
          filters={{
            dropdowns: [
              {
                labelText: "By Location",
                options: ["Show All"].concat(roofOptions),
                convex: true,
              },
            ],
            toggles: [
              {
                active: false,
                labelText: "Recent",
              },
              {
                active: false,
                labelText: "Flagged",
              },
              // {
              //   active: false,
              //   labelText: "Oldest",
              // },
              {
                active: false,
                labelText: "Resolved",
              },
            ],
          }}
          results={{
            useGroups: true,
            results: [
              { title: "Unresolved", results: unresolvedReviews },
              { title: "Resolved", results: resolvedReviews },
            ],
          }}
        />
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
