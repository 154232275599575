import { clearNewTokenValue } from '../actions/developerActions';
import { developerState, Constants } from '../types/developerTypes';

const initialState: developerState = {
    devTokens: [],
    newTokenValue: ''
}

export default function developerReducer(state = initialState, action: any) {
    switch (action.type) {
        case Constants.SET_DEV_TOKENS:
            return { ...state, devTokens: action.payload }

        case Constants.SET_NEW_TOKEN_VALUE:
            const { token } = action.payload
            return { ...state, newTokenValue: token }

        case Constants.CLEAR_NEW_TOKEN_VALUE:
            return { ...state, newTokenValue: '' }

        default:
            return state
    }
}