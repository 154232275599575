import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { ReduxState } from '../../../redux/store';
import { Dispatch, Action } from 'redux';
import { getOrgData } from '../../../redux/actions/orgActions';
import { updateOrgName, updateOrgLogo } from '../../../redux/actions/adminActions/adminOrgActions';

import Nav from '../../../components/Nav';
import Card from '../../../components/Card';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import ButtonSubmit from '../../../components/ButtonSubmit';

const mapStateToProps = ({ user, org }: ReduxState) => {
    const { orgId } = user
    const { orgData } = org
    const { orgName, orgLogo } = orgData
    return { orgId, orgName, orgLogo }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        getOrgData: (orgId: number) => dispatch(getOrgData(orgId)),
        updateOrgLogo: (orgId: number, data: any) => dispatch(updateOrgLogo(orgId, data)),
        updateOrgName: (orgId: number, organization: object) => dispatch(updateOrgName(orgId, organization))
    }
}

interface Props {
    orgId: number
    orgName: string
    orgLogo: string
    getOrgData: Function
    updateOrgLogo: Function
    updateOrgName: Function
}

const OrgConfig: FunctionComponent<Props> = ({ orgId, orgName, orgLogo, getOrgData, updateOrgLogo, updateOrgName }) => {

    const history: any = useHistory()

    //state
    const [name, setName] = useState('')
    const [logoDisplay, setLogoDisplay] = useState('')
    const [logoData, setLogoData]: any = useState()

    useEffect(() => {
        getOrgData(orgId)
    }, [])

    useEffect(() => {
        setName(orgName)
        setLogoDisplay(orgLogo)
    }, [orgName, orgLogo])

    //events
    const handleLogoChange = (e: any) => {
        const file = URL.createObjectURL(e.target.files[0])
        setLogoDisplay(file)
        setLogoData(e.target.files[0])
    }

    const handleFormSubmit = () => {
        if (logoData) {
            //create a new instance of the FormData object
            //and append the key/value pair logo: logoData to it
            //this allows the browser to set requestHeaders automatically 
            let formData = new FormData()
            formData.append("logo", logoData)
            updateOrgLogo(orgId, formData)
        }

        const data = { name }
        updateOrgName(orgId, data)

        //TODO
        //notification on success or failure
        //of both together 
        //OR each separately...

        history.push('/admin-home')
    }

    return (
        <div>
            <Nav
                backButtonExists={true}
                middleExists={false}
                buttonText={''}
                buttonLink={''}
                handleButtonClick={() => false}
                adminHomeExists={true}
                logoIsLink={true}
            />
            <Card title={'My Organization'}>

                <Form submitForm={handleFormSubmit}>
                    <div>
                        <label>Organization Logo</label>
                        <div className="flex items-center justify-center border-solid border-2 border-ozRepGreen p-8">
                            <img src={logoDisplay} alt={orgName} className="max-w-full max-h-16" />
                        </div>

                        <div className="flex items-center justify-center text-center text-white -mt-4">
                            <div className="w-2/5 flex flex-col items-center bg-white">
                                <label className="inline-block bg-ozRepGreen rounded-full p-1" htmlFor="upload">Change Logo</label>
                                <input type="file" id="upload" accept=".png,.jpeg,.jpg,.webp" className="hidden" onChange={e => handleLogoChange(e)} />
                            </div>
                        </div>
                    </div>

                    <Input
                        labelText="Organization Name"
                        inputType="text"
                        value={name}
                        onChange={setName} />

                    <ButtonSubmit
                        buttonText={'Update'}
                    />
                </Form>
            </Card>

        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgConfig);