import { OzRepAPI } from "../OzRepAPIClient";
import { Constants, Platform } from "../types/customerTypes";
import { Constants as notificationConsts } from "../types/notificationTypes";
import { showNotification } from "../actions/notificationActions";
import { Dispatch } from "redux";

export function getOrgAndRoofInfo(token: number): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.get(`/v2/reviewrequests/${token}`)
      .then((res) => {
        dispatch({
          type: Constants.GET_ORG_AND_ROOF_INFO,
          payload: res.data.review_request,
        });
      })
      .catch((err) => {
        //error messaging
        console.log(err);
      });
  };
}

export function sendNegativeReview(token: number, review: object): any {
  return function (dispatch: Dispatch) {
    OzRepAPI.post(`/v2/reviewrequests/${token}`, { review })
      .then((res) => {
        const message = "Review sent";
        dispatch(showNotification(notificationConsts.SHOW_SUCCESS, message));
      })
      .catch((err) => {
        const message = "Failed to send review";
        dispatch(showNotification(notificationConsts.SHOW_ERROR, message));
      });
  };
}
export function trackClicked(token: string, url: Platform) {
  OzRepAPI.post(`/v2/reviewrequests/mark/${token}`, url)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
