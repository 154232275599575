import React, { FunctionComponent, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Smile, Frown } from "react-feather";

import { connect } from "react-redux";
import { ReduxState } from "../../redux/store";
import { Dispatch, Action } from "redux";
import { Platform } from "../../redux/types/customerTypes";
import { getOrgAndRoofInfo } from "../../redux/actions/customerActions";
import { trackClicked } from "../../redux/actions/customerActions";

import Nav from "../../components/Nav";
import Card from "../../components/Card";
import OrgLogo from "../../components/OrgLogo";
import Pill from "../../components/Pill";

const mapStateToProps = ({ customer }: ReduxState) => {
  const { orgName, orgLogo, roofName, reviewURLs } = customer;
  return { orgName, orgLogo, roofName, reviewURLs };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    getOrgAndRoofInfo: (requestId: number) =>
      dispatch(getOrgAndRoofInfo(requestId)),
  };
};

interface Props {
  orgName: string;
  orgLogo: string | null;
  roofName: string;
  reviewURLs: Platform[];
  getOrgAndRoofInfo: Function;
}

const Rate: FunctionComponent<Props> = ({
  orgName,
  orgLogo,
  roofName,
  reviewURLs,
  getOrgAndRoofInfo,
}) => {
  const params: any = useParams();
  const token: string = params.token;
  const history: any = useHistory();

  useEffect(() => {
    getOrgAndRoofInfo(token);
  }, []);

  const handlePositiveReview = () => {
    //TODO
    //1. check to see if there is more than one enabled platform
    const urls = reviewURLs.filter((item: Platform) => item.enabled === true);
    if (urls.length <= 1) {
      //2. if there is only one, track the click and go to that platform's review url
      trackClicked(token, urls[0]);
      window.open(urls[0].url, "_blank");
    } else if (urls.length > 1) {
      //3. if there are more than one, go to a new page that gives all the platforms as options
      //4. on the new page, track which platform is clicked and go to the clicked platforms review url
      history.push(`/review/${token}`);
    }
  };

  return (
    <div>
      <Nav
        backButtonExists={false}
        middleExists={false}
        buttonText=""
        buttonLink=""
        handleButtonClick={() => false}
        adminHomeExists={false}
        logoIsLink={false}
      />

      <Card title={"How Was Your Experience?"}>
        <div className="grid grid-cols-1 row-auto gap-y-6">
          <OrgLogo orgName={orgName} rooftopName={roofName} logoSrc={orgLogo} />

          <div className="grid grid-cols-1 row-auto gap-y-6">
            <div data-testid="positive-review" onClick={handlePositiveReview}>
              <Pill
                color={"bg-ozRepGreen"}
                textColor={"text-white"}
                shadow={true}
              >
                <p>It was great!</p>
                <Smile />
              </Pill>
            </div>

            <Link to={`/feedback/${token}`}>
              <Pill color={"bg-white"} textColor={"text-black"} shadow={true}>
                <p>Not so great...</p>
                <Frown />
              </Pill>
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Rate);
