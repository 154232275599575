export interface NotificationState {
    notificationType: string,
    notificationMessage: string
}

export enum Constants  {
    SHOW_SUCCESS = "SHOW_SUCCESS",
    SHOW_ERROR = "SHOW_ERROR",
    SHOW_WARNING = "SHOW_WARNING",
    CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION"
}