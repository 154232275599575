import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { ReduxState } from '../../redux/store';
import { Dispatch, Action } from 'redux';
import { getOrgAndRoofInfo, sendNegativeReview } from '../../redux/actions/customerActions';

import Nav from '../../components/Nav';
import Card from '../../components/Card';
import OrgLogo from '../../components/OrgLogo';
import Form from '../../components/Form';
import TextArea from '../../components/TextArea';
import ButtonSubmit from '../../components/ButtonSubmit';

const mapStateToProps = ({ customer }: ReduxState) => {
  const { orgName, orgLogo, roofName } = customer
  return { orgName, orgLogo, roofName }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    getOrgAndRoofInfo: (requestId: number) => dispatch(getOrgAndRoofInfo(requestId)),
    sendNegativeReview: (token: number, reviewInfo: object) => dispatch(sendNegativeReview(token, reviewInfo))
  }
}

interface Props {
  orgName: string,
  orgLogo: string | null,
  roofName: string,
  getOrgAndRoofInfo: Function,
  sendNegativeReview: Function,
}

const Feedback: FunctionComponent<Props> = ({ orgName, orgLogo, roofName, sendNegativeReview, getOrgAndRoofInfo }) => {

  let history: any = useHistory()
  let params: any = useParams()
  const token: number = params.token

  //state
  const [message, setInput] = useState('')
  const [userWantsResponse, setCheckbox] = useState(false)

  useEffect(() => {
    if (!orgName && !orgLogo && !roofName) {
      getOrgAndRoofInfo(token)
    }
  }, [])

  //events
  const handleCheckbox = () => {
    const checkboxState = !userWantsResponse
    setCheckbox(checkboxState)
  }

  const handleFormSubmit = () => {
    const reveiewInfo = { message: message, wants_response: userWantsResponse }
    sendNegativeReview(token, reveiewInfo)
    history.push(`/thanks/${token}`)
  }

  return (
    <div>

      <Nav
        backButtonExists={true}
        middleExists={false}
        buttonText=''
        buttonLink=''
        handleButtonClick={() => false}
        adminHomeExists={false}
        logoIsLink={false}
      />

      <Card title={'Tell Us About Your Experience'}>

        <OrgLogo
          orgName={orgName}
          rooftopName={roofName}
          logoSrc={orgLogo} />

        <Form submitForm={handleFormSubmit}>
          <div>
            <h1 className="font-bold text-xl text-center mb-4">How can we do better?</h1>

            <TextArea
              height="40"
              name="feedback"
              maxLength={450}
              onChange={setInput}
            />

            <div className="m-3">
              <input type='checkbox' onChange={handleCheckbox} />
              <label className="m-2">I would like a response</label>
            </div>
          </div>

          <ButtonSubmit
            buttonText={'Send'}
          />
        </Form>
      </Card>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);




