import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Filter } from 'react-feather';

import SearchBar from '../SearchBar';
import Dropdown from '../Dropdown';
import { ToggleableFilter } from './TogglableFilter';
import TwoRowPill from './../TwoRowPill';
import { ReviewObject } from '../../redux/types/reviewTypes';

export interface SearchableFilterableListProps {
    onSearchOrFilter: Function
    initialSearchAndFilterState: {}
    onInfiniteScrollTriggered: Function
    filters: Filters
    results: SearchResults
}

interface Filters {
    dropdowns: Dropdowns[]
    toggles: Toggles[]
}

interface Dropdowns {
    labelText: string
    options: string[]
    convex: boolean
    value?: string
}

interface Toggles {
    active: boolean,
    labelText: string
}

interface SearchResults {
    useGroups: boolean
    results: SearchResultsGroup[]
}

interface SearchResultsGroup {
    title: string
    results: ReviewObject[]
}

type SearchableFilterableListState = {
    showFilters: boolean,
    firstIntersectionObserved: boolean
    searchAndFilterInput: {}
}

class SearchableFilterableList extends Component<SearchableFilterableListProps, SearchableFilterableListState> {

    constructor(props: SearchableFilterableListProps) {
        super(props);

        this.state = {
            showFilters: false,
            firstIntersectionObserved: false,
            searchAndFilterInput: {}
        }

        this.toggleFiltersOnAndOff = this.toggleFiltersOnAndOff.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.RenderDropdowns = this.RenderDropdowns.bind(this);
        this.RenderTogglableFilters = this.RenderTogglableFilters.bind(this);
        this.triggerInfiniteScroll = this.triggerInfiniteScroll.bind(this);
    }

    componentDidMount() {
        // Create and mount the observer for the infinite scroll trigger
        let options = {
            rootMargin: '0px',
            threshold: 0.1
        }

        let observer = new IntersectionObserver(this.triggerInfiniteScroll, options)

        let target: any = document.querySelector('#infinite-scroll-trigger');

        observer.observe(target);

        //initialize the searchAndFilterInput state with
        //the default values on props
        this.setState(state => {
            return {
                searchAndFilterInput: this.props.initialSearchAndFilterState
            }
        })
    }

    componentDidUpdate(prevProps: SearchableFilterableListProps, prevState: SearchableFilterableListState) {
        //when a change to the searchAndFilterInput on state occurs
        //trigger the onSearchOrFilter function from props
        //with the new state value
        const { onSearchOrFilter } = this.props
        const { searchAndFilterInput } = this.state
        if (searchAndFilterInput !== prevState.searchAndFilterInput) {
            onSearchOrFilter(searchAndFilterInput)
        }
    }

    toggleFiltersOnAndOff() {
        this.setState((state) => ({
            showFilters: !state.showFilters
        }));
    }

    handleSearch(value: string) {
        this.setState(state => {
            return {
                searchAndFilterInput: {
                    ...state.searchAndFilterInput,
                    searchInput: value
                }
            }
        })
    }

    handleToggle(label: string, value: boolean) {
        const name: string = label.toLowerCase().replace(/\s/g, '')
        this.setState(state => {
            return {
                searchAndFilterInput: {
                    ...state.searchAndFilterInput,
                    [name]: value
                }
            }
        })
    }

    RenderDropdowns(dropdowns: Dropdowns[]) {

        let outputList = dropdowns.map((dropdown, index) =>
            <div key={`dropdown-filter-${index}`} className="mb-4">
                <Dropdown
                    labelText={dropdown.labelText}
                    options={dropdown.options}
                    onChange={this.handleToggle}
                    value={dropdown.value}
                    convex={true}
                />
            </div>
        );

        return (
            <div id="inner-filters-dropdown-box" className="mb-4 w-full">
                {outputList}
            </div>
        );

    }

    RenderTogglableFilters(filters: Toggles[]) {

        let outputList = filters.map((filter, index) =>
            <div key={`toggle-filter-${index}`} className={'mt-4 ' + (index % 2 != 0 ? ' justify-self-end' : '')}>
                <ToggleableFilter
                    active={filter.active}
                    labelText={filter.labelText}
                    onToggle={this.handleToggle} />
            </div>
        );

        return (
            <div id="inner-filters-toggles-box" className="grid grid-cols-2 grid-flow-row">
                {outputList}
            </div>
        );
    }

    triggerInfiniteScroll(entries: IntersectionObserverEntry[]) {
        entries.forEach(entry => {
            // Make some checks to only trigger the callback when it's actually
            // observed and not when it's first mounted into the DOM or 
            // *leaves* the viewport
            if (entry.isIntersecting) {
                if (this.state.firstIntersectionObserved) {
                    this.props.onInfiniteScrollTriggered()
                } else {
                    this.setState((state) => ({
                        firstIntersectionObserved: true
                    }));
                }
            }
        });
    }

    render() {
        return (
            <div className="searchable-filterable-list">
                <div className="flex justify-between items-center mb-2">
                    <div id="search-bar-container" className="min-w-0">
                        <SearchBar placeholder="Search Reviews..." value="" onInput={this.handleSearch} />
                    </div>
                    <div
                        id="filter-button"
                        className={"flex justify-center items-center bg-ozRepGreen rounded-full p-3 ml-2 min-w-0 cursor-pointer " + (this.state.showFilters ? 'shadow-inner' : 'shadow')}
                        onClick={this.toggleFiltersOnAndOff}
                    >
                        <span className="text-white font-semibold pr-2">Filter</span>
                        <Filter color="white" />
                    </div>
                </div>
                <div
                    id="filters-box"
                    className={`shadow-inner bg-ozRepGray my-2 p-4 rounded-lg ${this.state.showFilters ? 'fade-in' : 'hidden'}`}
                >
                    {this.RenderDropdowns(this.props.filters.dropdowns)}
                    <label>Options</label>
                    <div id="toggles">
                        {this.RenderTogglableFilters(this.props.filters.toggles)}
                    </div>
                </div>
                <div id="results-list">
                    {RenderResultList(this.props.results)}
                </div>
                <div id="infinite-scroll-trigger"></div>
            </div>
        )
    }
}


function RenderResultList(results: SearchResults) {

    if (results.results.length === 0) {
        return <p className="text-center text-textGray italic mt-4">No Results Found</p>
    }

    if (results.useGroups) {

        let outputList = results.results.map((resultGroup, index) =>
            RenderResultListGroup(resultGroup.title, resultGroup.results)
        );

        return outputList;
    }

    // If you're not using groups, just display all of the results in the first "group" 
    return RenderResultListGroup('', results.results[0].results)
}

function RenderResultListGroup(title: string | any, results: ReviewObject[]) {

    if (results.length === 0) {
        return <div key={`result-list-group-${title}`} className="result-list-group-container">
            <div className="result-list-group-title">
                {title ? (<p className="text-lg text-gray-600 font-semibold">{title}</p>) : ''}
                {title ? (<hr></hr>) : ''}
            </div>
            <div className="result-list-group-contents">
                <p className="text-center text-textGray italic mt-4">No Results Found</p>
            </div>
        </div>
    }

    let outputList = results.map((result, index) =>
        <div className="result-container" key={`${title}-results-${index}`}>
            <Link to={result.link}>
                <TwoRowPill
                    title={result.title}
                    subTitle={result.subTitle}
                    emphasized={!result.emphasized}
                    flagged={result.flagged} />
            </Link>
        </div>
    );

    return (
        <div key={`result-list-group-${title}`} className="result-list-group-container">
            <div className="result-list-group-title">
                {title ? (<p className="text-lg text-gray-600 font-semibold">{title}</p>) : ''}
                {title ? (<hr></hr>) : ''}
            </div>
            <div className="result-list-group-contents">
                {outputList}
            </div>
        </div>
    );
}


export default SearchableFilterableList;
