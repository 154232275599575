import { Constants } from '../types/notificationTypes';
import { Dispatch } from 'redux';

export function showNotification(notificationType: string, notificationMessage: string, clearDelay: number = 3000): any {

    return (dispatch: Dispatch) => {

        setTimeout(() => {
            dispatch(clearNotification())
        }, clearDelay)

        dispatch({ type: notificationType, payload: notificationMessage })
    }
}

// export function warning(payload: string) {
//     return {
//         type: Constants.SHOW_WARNING,
//         payload
//     }
// }

export function clearNotification(): any {
    return function (dispatch: Dispatch) {
        dispatch({ type: Constants.CLEAR_NOTIFICATION })
    }
}

