import React, { FunctionComponent, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Trash2 } from "react-feather";
import { OzRepAPI } from "../../../redux/OzRepAPIClient";

import { connect, useSelector } from "react-redux";
import { Dispatch, Action } from "redux";
import {
  updateUserAsAdmin,
  deactivateUser,
} from "../../../redux/actions/adminActions/adminUserActions";
import {
  showNotification,
  clearNotification,
} from "../../../redux/actions/notificationActions";
import { Constants } from "../../../redux/types/notificationTypes";

//NOT mapped to props via connect function
import { getOrgUser } from "../../../redux/actions/orgActions";

import Nav from "../../../components/Nav";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Input from "../../../components/Input";
import Dropdown from "../../../components/Dropdown";
import ButtonSubmit from "../../../components/ButtonSubmit";
import Pill from "../../../components/Pill";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    updateUserAsAdmin: (orgId: number, userId: number, data: any) =>
      dispatch(updateUserAsAdmin(orgId, userId, data)),
    deactivateUser: (orgId: number, userId: number) =>
      dispatch(deactivateUser(orgId, userId)),
    showNotification: (
      notificationType: string,
      notificationMessage: string,
      clearDelay: number
    ) =>
      dispatch(
        showNotification(notificationType, notificationMessage, clearDelay)
      ),
    clearNotification: () => dispatch(clearNotification()),
  };
};

interface Props {
  updateUserAsAdmin: Function;
  deactivateUser: Function;
  showNotification: Function;
  clearNotification: Function;
}

const ManageUser: FunctionComponent<Props> = ({
  updateUserAsAdmin,
  deactivateUser,
  showNotification,
  clearNotification,
}) => {
  //pull the userId of the user to manage from params
  const params: any = useParams();
  const paramUserId: number = params.userId;

  const history: any = useHistory();

  //pull userId of the current user from user redux state
  //and listen for changes to userId
  const { userId } = useSelector((state: any) => {
    return state.user;
  });

  //state
  const [originalUser, setUser] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("user");
  const [error, setError] = useState("");

  useEffect(() => {
    const { userId, orgId } = params;
    getOrgUser(orgId, userId)
      .then((res: any) => {
        const { name, email, role } = res.data.user;
        setUser(res.data.user);
        setName(name);
        setEmail(email);
        setRole(role);
      })
      //what error handling methods should we use?
      .catch((err: any) => {
        //error handling
      });

    //componentWillUnmount
    return () => {
      clearNotification();
    };
  }, []);

  //events
  const handleFormSubmit = async () => {
    const { userId, orgId } = params;

    //if the email was updated, make a check to see if it is unique
    if (email !== originalUser.email) {
      //make sure email is unique within org
      const users = await OzRepAPI.get(`/v2/organizations/${orgId}/users`);
      const match = users.data.users.filter(
        (user: any) => user.email === email
      );

      if (match.length > 0) {
        const message = "Email address already taken.";
        setError(message);
        showNotification(Constants.SHOW_WARNING, message);
      } else {
        submitUpdate();
      }
    } else {
      submitUpdate();
    }
  };

  const submitUpdate = () => {
    const { userId, orgId } = params;
    const data = { name, email, role };
    updateUserAsAdmin(userId, orgId, data);
    history.push("/users");
  };

  const handleUserDelete = () => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const { userId, orgId } = params;
      deactivateUser(orgId, userId);
      history.push("/users");
    }
  };

  return (
    <div>
      <Nav
        backButtonExists={true}
        middleExists={false}
        buttonText={""}
        buttonLink={""}
        handleButtonClick={() => false}
        adminHomeExists={true}
        logoIsLink={true}
      />
      <Card title={"Manage User"}>
        <Form submitForm={handleFormSubmit}>
          <Input
            labelText="Name"
            inputType="text"
            value={name}
            onChange={setName}
          />

          <Input
            labelText="Email"
            inputType="text"
            inputMode="email"
            value={email}
            onChange={setEmail}
          />
          {error && <p className="-mt-5 text-red-500">{error}</p>}

          <Dropdown
            labelText="Permissions Level"
            options={["user", "admin", "owner"]}
            value={role}
            onChange={setRole}
          />

          <ButtonSubmit buttonText="Update" />
        </Form>

        {userId != paramUserId && (
          <div onClick={handleUserDelete} className="cursor-pointer mt-6">
            <Pill color={"bg-red-700"} textColor={"text-white"} shadow={true}>
              <p>Delete User</p>
              <Trash2 />
            </Pill>
          </div>
        )}
      </Card>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ManageUser);
