import React, { FunctionComponent } from "react";

interface Props {
  labelText: string;
  inputType: string;
  inputMode?: any;
  value: string;
  name?: string;
  required?: boolean;
  onChange: Function;
  show?: boolean;
}

const Input: FunctionComponent<Props> = ({
  labelText,
  inputType,
  inputMode = "text",
  value,
  name,
  required,
  onChange,
}) => {
  const setInput = (value: number | string | null) => {
    onChange(value);
  };

  return (
    <div>
      <label>
        {labelText}
        <input
          data-testid="input-component"
          type={inputType}
          value={value}
          name={name}
          inputMode={inputMode}
          required={required}
          onChange={(e) => setInput(e.target.value)}
          className="h-12 w-full rounded-full bg-ozRepGray shadow-inner outline-none pl-3"
        />
      </label>
    </div>
  );
};

export default Input;
