import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import Nav from '../../../components/Nav';
import OrgLogo from '../../../components/OrgLogo';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

const EmailReview: FunctionComponent = () => {

    //methods
    const handleInput = () => {
        console.log('input inputted')
    }
    const handleButtonClick = () => {
        console.log('button has been clicked')
    }
    return (
        <div>
            <Nav
                backButtonExists={true}
                middleExists={false}
                buttonText={''}
                buttonLink={''}
                handleButtonClick={() => false}
                adminHomeExists={false}
                logoIsLink={true}
            />
            <Card title={'Email Review'}>

                <div className="grid grid-cols-1 row-auto gap-y-6">

                    <OrgLogo
                        orgName={''}
                        rooftopName={''}
                        logoSrc={''} />

                    <div className="text-ozRepGreen text-center border-solid border-2 border-ozRepGreen p-2">
                        <p>385-375-9749</p>
                        <p>My dog ate a tire and died on the lot. The lot techs did nothing. Now I have to bury a VERY bouncy dog.</p>
                    </div>

                    <Input
                        labelText="Email To:"
                        inputType="text"
                        value=""
                        onChange={handleInput} />

                    <Link to='/reviews'>
                        <Button
                            buttonText={'Send'}
                            onClick={handleButtonClick} />
                    </Link>
                </div>
            </Card>

        </div>
    )
}

export default EmailReview;